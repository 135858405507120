import React, { useState } from "react";
import axios from "axios";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import { Input, Select, Button } from "antd";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

const AddApplication = ({ setOpen }) => {
  const apiUrl = process.env.REACT_APP_API_Applications;
  const [name, setName] = useState("");
  const [app_type, setApp_type] = useState(undefined);
  const [errors, setErrors] = useState({
    name: "",
    app_type: "",
    general: "",
  });

  const handleNameChange = (e) => {
    setName(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
  };

  const handleAppTypeChange = (value) => {
    setApp_type(value);
    setErrors((prevErrors) => ({ ...prevErrors, app_type: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!name.trim() && !app_type.trim()) {
      newErrors.name = "Please enter the application name";
      newErrors.app_type = "Please select the app type";
      setErrors(newErrors);
      return;
    }
    if (!name.trim()) {
      newErrors.name = "Please enter the application name";
      setErrors(newErrors);
      return;
    }
    if (!app_type.trim()) {
      newErrors.app_type = "Please select the app type";
      setErrors(newErrors);
      return;
    }

    try {
      const response = await axios.post(apiUrl, { name, app_type, visible: 1 });
      if (response.status === 200) {
        setOpen(false);
      } else {
        throw new Error("Failed to add team");
      }
    } catch (error) {
      console.error("Error adding team:", error);
      setErrors({ general: "Failed to add team" });
    }
  };

  return (
    <>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ ml: 2, flex: 1 }}>
            Add Application
          </Typography>
        </Toolbar>
      </AppBar>
      <List style={{ height: "100vh" }}>
        <Grid
          container
          style={{ height: "100%", justifyContent: "center" }}
          alignItems="center"
        >
          <Grid item xs={12} sm={8} md={6} lg={8}>
            <form onSubmit={handleSubmit} className="mx-10">
              <div className="mb-4">
                <h2 className="text-2xl font-bold">Add Application</h2>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <Input
                  value={name}
                  onChange={handleNameChange}
                  placeholder="Enter Application Name"
                  addonBefore="Enter Application Name"
                  className="mb-4"
                />
                <Select
                  placeholder="Select Application Type"
                  value={app_type}
                  onChange={handleAppTypeChange}
                  className="mb-4"
                >
                  <Select.Option value="Web">Web</Select.Option>
                  <Select.Option value="Android">Android</Select.Option>
                </Select>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white] ml-2"
                >
                  Add Application
                </button>
              </div>
            </form>
          </Grid>
        </Grid>
      </List>
    </>
  );
};

export default AddApplication;
