import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Space, Table, message, Modal } from "antd";
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Typography from "@mui/material/Typography";
import Highlighter from "react-highlight-words";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import AddTeamForm from "./addTeam";
import ViewTeamDetail from "./viewTeamDetails";
import EditTeam from "./editTeam";
import { ExpandAltOutlined } from "@ant-design/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const apiUrl = process.env.REACT_APP_API_Team;
const countryAPI = process.env.REACT_APP_API_Countries;
const Teams = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [teams, setTeams] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [teamId, setTeamId] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [viewModalVisible, setviewModalVisible] = useState(false);
  const [editModalVisible, setEidtModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    fetchData();
  }, [setOpen, open, editModalVisible, setEidtModalVisible]);

  const storedUser = localStorage.getItem("user");
  const user = JSON.parse(storedUser);

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch teams");
      }
      const data = await response.json();
      setTeams(data.body);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            type="secondary"
            className="bg-[blue] text-[white]"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleViewClick = (record) => {
    setviewModalVisible(true);
    setTeamId(record.id);
  };

  const handleEditClick = (record) => {
    setEidtModalVisible(true);
    setTeamId(record.id);
  };

  const handleDeleteClick = (record) => {
    setSelectedRecord(record);
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    // Make API call to delete the selected record
    try {
      const response = await fetch(`${apiUrl}/${selectedRecord.id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        message.success("Record deleted successfully");
        setTeams(teams.filter((record) => record.id !== selectedRecord.id));
      } else {
        message.error("Failed to delete record");
      }
    } catch (error) {
      console.error("Error deleting record:", error);
      message.error("Failed to delete record");
    }
    setDeleteModalVisible(false);
  };

  const handleDeleteCancel = () => {
    // Cancel delete action
    setDeleteModalVisible(false);
  };

  const handleAddTesterClick = () => {
    setOpen(true);
  };

  const columns = [
    {
      title: "Team Name",
      dataIndex: "name",
      key: "name",
      width: "12%",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Country",
      dataIndex: "country_name",
      key: "country_name",
      width: "10%",
      ...getColumnSearchProps("country_name"),
      sorter: (a, b) => a.country_name.localeCompare(b.country_name),
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Actions",
      dataIndex: "view",
      key: "view",
      width: "20%",
      render: (text, record) => (
        <Space>
          <Button
            icon={<ExpandAltOutlined />}
            onClick={() => handleViewClick(record)}
          >
            View Details
          </Button>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEditClick(record)}
          >
            Edit
          </Button>
          {/* <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteClick(record)}
            danger
          >
            Delete
          </Button> */}
        </Space>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
          All Teams
        </Typography>
        <Button icon={<PlusOutlined />} onClick={handleAddTesterClick}>
          Add Team
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={teams}
        loading={loading}
        locale={{
          emptyText: loading ? 'Loading...' : 'No Data',
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 5,
          pageSizeOptions: ["5", "10", "20"],
        }}
        scroll={{ x: "100%" }}
      />
      <Modal
        title="Confirm Delete"
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        okButtonProps={{ style: { backgroundColor: "red" } }}
      >
        Are you sure you want to delete this record?
      </Modal>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        style={{ zIndex: 20 }}
      >
        <AddTeamForm setOpen={setOpen} />
      </Dialog>
      <Dialog
        fullScreen
        open={viewModalVisible}
        onClose={() => setviewModalVisible(false)}
        TransitionComponent={Transition}
        style={{ zIndex: 20 }}
      >
        <ViewTeamDetail setOpen={setviewModalVisible} teamId={teamId} />
      </Dialog>
      <Dialog
        fullScreen
        open={editModalVisible}
        onClose={() => setEidtModalVisible(false)}
        TransitionComponent={Transition}
        style={{ zIndex: 20 }}
      >
        <EditTeam setOpen={setEidtModalVisible} id={teamId} />
      </Dialog>
    </>
  );
};

export default Teams;
