export const downloadCSV = (numbers) => {
  let csvData = "Number\n";
  numbers.forEach((number) => {
    csvData += `${number}\n`;
  });
  const storedUser = localStorage.getItem("user");
  const user = JSON.parse(storedUser) || {};
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-GB");
  link.download = `${user.name}-${formattedDate}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
