import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Input, Select, Button } from "antd";

const EditApplication = ({ id, setOpen }) => {
  const apiUrl = process.env.REACT_APP_API_Applications;
  const [name, setName] = useState("");
  const [app_type, setApp_type] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    app_type: "",
    general: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchApplicationInfo();
  }, []);

  const fetchApplicationInfo = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/${id}`);
      const appData = response.data.body;
      setName(appData.name);
      setApp_type(appData.app_type);
    } catch (error) {
      console.error("Error fetching application info:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTeamNameChange = (e) => {
    setName(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
  };

  const handleAppTypeChange = (value) => {
    setApp_type(value);
    setErrors((prevErrors) => ({ ...prevErrors, app_type: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!name.trim()) {
      newErrors.name = "Please enter the application name";
      setErrors(newErrors);
      return;
    }
    if (!app_type) {
      newErrors.app_type = "Please select the app type";
      setErrors(newErrors);
      return;
    }

    try {
      setLoading(true);
      const response = await axios.put(`${apiUrl}/${id}`, {
        name,
        app_type,
        visible: 1,
      });
      if (response.status === 200) {
        setOpen(false);
      } else {
        throw new Error("Failed to edit application");
      }
    } catch (error) {
      console.error("Error editing application:", error);
      setErrors({ general: "Failed to edit application" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ ml: 2, flex: 1 }}>
            Edit Application
          </Typography>
        </Toolbar>
      </AppBar>
      <List style={{ height: "100vh" }}>
        <Grid
          container
          style={{ height: "100%", justifyContent: "center" }}
          alignItems="center"
        >
          <Grid item xs={12} sm={8} md={6} lg={8}>
            <form onSubmit={handleSubmit} className="mx-10">
              <div className=" mb-4">
                <h2 className="text-2xl font-bold">Edit Application</h2>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <Input
                  value={name}
                  onChange={handleTeamNameChange}
                  placeholder="Enter Application Name"
                  addonBefore="Enter Application Name"
                  className="mb-4"
                />
                <Select
                  placeholder="Select Application Type"
                  value={app_type}
                  onChange={handleAppTypeChange}
                  className="mb-4"
                >
                  <Select.Option value="Web">Web</Select.Option>
                  <Select.Option value="Android">Android</Select.Option>
                </Select>
              </div>

              {errors.name && (
                <p className="text-red-500 text-xs">{errors.name}</p>
              )}
              {errors.app_type && (
                <p className="text-red-500 text-xs">{errors.app_type}</p>
              )}
              {errors.general && (
                <p className="text-red-500 text-xs">{errors.general}</p>
              )}

              <div className="flex justify-end">
                <Button
                  type="secondary"
                  className="bg-[blue] text-[white] ml-2"
                  htmlType="submit"
                  loading={loading}
                >
                  Edit Application
                </Button>
              </div>
            </form>
          </Grid>
        </Grid>
      </List>
    </>
  );
};

export default EditApplication;
