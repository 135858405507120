import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  Grid,
} from "@mui/material";
import { Spin, Descriptions } from "antd";
import CloseIcon from "@mui/icons-material/Close";

const ViewApplication = ({ setOpen, appId }) => {
  const [appData, setAppData] = useState(null);
  const apiUrl = process.env.REACT_APP_API_Applications;

  useEffect(() => {
    const fetchApplicationDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/${appId}`);
        setAppData(response.data.body);
      } catch (error) {
        console.error("Error fetching application details:", error);
      }
    };

    fetchApplicationDetails();
    return () => {
      setAppData(null);
    };
  }, [appId]);

  if (!appData) {
    return <Spin />;
  }

  return (
    <>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ ml: 2, flex: 1 }}>
            View Application Details
          </Typography>
        </Toolbar>
      </AppBar>
      <List style={{ height: "50vh" }}>
        <Grid
          container
          style={{ height: "50%", justifyContent: "center" }}
          alignItems="center"
        >
          <Grid item xs={12} sm={8} md={6} lg={8}>
            <h2 className="text-2xl font-bold mt-8 mb-8">
              Application Details
            </h2>
            <Descriptions column={1} bordered>
              <Descriptions.Item label="Application ID">
                {appData.id}
              </Descriptions.Item>
              <Descriptions.Item label="Application Name">
                {appData.name}
              </Descriptions.Item>
              <Descriptions.Item label="Application Type">
                {appData.app_type}
              </Descriptions.Item>
            </Descriptions>
          </Grid>
        </Grid>
      </List>
    </>
  );
};

export default ViewApplication;
