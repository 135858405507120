import React, { useState, useEffect } from "react";
import axios from "axios";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import { Input, Select, Button } from "antd";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const EditTeamlead = ({ setOpen, id }) => {
  const teamsURL = process.env.REACT_APP_API_Team;
  const teamleadAPI = process.env.REACT_APP_API_Teamleads;
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [teams, setTeams] = useState([]);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [contact_number, setContactNumber] = useState("");
  const [gender, setGender] = useState("");
  const [team, setTeam] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirmPassword: "",
    contact_number: "",
    gender: "",
    team: "",
  });

  useEffect(() => {
    if (id) {
      fetchTeamleadData(id);
    }
  }, [id]);

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    setLoading(true);
    try {
      const response = await axios.get(teamsURL);
      setTeams(response.data.body);
    } catch (error) {
      console.error("Error fetching teams:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTeamleadData = async (id) => {
    try {
      const teamleadResponse = await axios.get(`${teamleadAPI}/${id}`);
      const testerData = teamleadResponse.data.body;

      setFirstName(testerData.first_name);
      setLastName(testerData.last_name);
      setEmail(testerData.email);
      setGender(testerData.gender);
      setContactNumber(testerData.contact_number);
      setPassword(testerData.password);
      setConfirmPassword(testerData.password);

      const teamResponse = await axios.get(teamsURL);
      const teamData = teamResponse.data.body;
      const teamLeadTeam = teamData.find((team) => team.team_lead_id === id);
      if (teamLeadTeam) {
        setTeam(teamLeadTeam.name);
      }
    } catch (error) {
      console.error("Error fetching tester data:", error);
    }
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    if (errors.first_name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        first_name: "",
      }));
    }
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    if (errors.last_name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        last_name: "",
      }));
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (errors.email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (errors.password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }
  };

  const handleContactNumberChange = (e) => {
    setContactNumber(e.target.value);
    if (errors.contact_number) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contact_number: "",
      }));
    }
  };

  const handleGenderChange = (value) => {
    setGender(value);
    if (errors.gender) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        gender: "",
      }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (errors.confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "",
      }));
    }
  };

  const handleTeamChange = (e) => {
    setTeam(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, team: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!password.trim()) {
      newErrors.password = "Please enter Password";
    } else if (password.trim().length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
    }

    const contactNumberRegex = /^(?:\+?92|0)\d{10}$/;
    if (!contact_number.trim()) {
      newErrors.contact_number = "Please enter Contact Number";
    } else if (!contactNumberRegex.test(contact_number.trim())) {
      newErrors.contact_number =
        "Please enter a valid contact number (e.g., +92331514193 or 03313423987)";
    }

    if (!first_name.trim()) {
      newErrors.first_name = "Please enter First Name";
    }
    if (!last_name.trim()) {
      newErrors.last_name = "Please enter Last Name";
    }
    if (!email.trim()) {
      newErrors.email = "Please enter Email";
    }
    if (!gender) {
      newErrors.gender = "Please select Gender";
    }
    if (!team) {
      newErrors.team = "Please select Team";
    }
    if (password.trim() !== confirmPassword.trim()) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const selectedTeam = teams.find((t) => t.name === team);
      if (!selectedTeam) {
        throw new Error("Selected team not found.");
      }

      const team_id = selectedTeam.id;
      const response = await axios.put(`${teamleadAPI}/${id}`, {
        first_name,
        last_name,
        gender,
        contact_number,
        email,
        password,
        role_id: 3,
        team_id,
      });
      if (response.status === 200) {
        setOpen(false);
      } else {
        throw new Error("Failed to edit team");
      }
    } catch (error) {
      console.error("Error editing team:", error);
      setErrors({ general: "Failed to edit team" });
    }
  };

  return (
    <>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Edit Teamlead
          </Typography>
        </Toolbar>
      </AppBar>
      <List style={{ height: "100vh" }}>
        <Grid
          container
          style={{ height: "100%", justifyContent: "center" }}
          alignItems="center"
        >
          <Grid item xs={12} sm={8} md={6} lg={8}>
            <form onSubmit={handleSubmit} className="mx-10">
              <div className=" mb-4">
                <h2 className="text-2xl font-bold">Edit Teamlead</h2>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <Input
                  value={first_name}
                  onChange={handleFirstNameChange}
                  placeholder="Enter First Name"
                  addonBefore="First Name"
                  className="mb-4"
                />
                {errors.first_name && (
                  <p className="text-red-500 text-xs">{errors.first_name}</p>
                )}
                <Input
                  value={last_name}
                  onChange={handleLastNameChange}
                  placeholder="Enter Last Name"
                  addonBefore="Last Name"
                  className="mb-4"
                />
                {errors.last_name && (
                  <p className="text-red-500 text-xs">{errors.last_name}</p>
                )}
                <Input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter Email"
                  addonBefore="Email"
                  className="mb-4"
                />
                {errors.email && (
                  <p className="text-red-500 text-xs">{errors.email}</p>
                )}
                <Input
                  type="tel"
                  value={contact_number}
                  onChange={handleContactNumberChange}
                  placeholder="Enter Contact Number"
                  addonBefore="Contact Number"
                  className="mb-4"
                />
                {errors.contact_number && (
                  <p className="text-red-500 text-xs">
                    {errors.contact_number}
                  </p>
                )}
                <div className="relative">
                  <Input.Password
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="Enter Password"
                    addonBefore="Password"
                    className="mb-4"
                  />
                  <div
                    className="absolute inset-y-0 right-0 top-5 flex items-center pr-3 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {/* {showPassword ? (
                      <AiOutlineEyeInvisible
                        size={25}
                        className="text-[gray]"
                      />
                    ) : (
                      <AiOutlineEye size={25} className="text-[gray]" />
                    )} */}
                  </div>
                </div>
                {errors.password && (
                  <p className="text-red-500 text-xs">{errors.password}</p>
                )}
                <div className="relative">
                  <Input.Password
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    placeholder="Confirm Password"
                    addonBefore="Confirm Password"
                    className="mb-4"
                  />
                  <div
                    className="absolute inset-y-0 right-0 top-5 flex items-center pr-3 cursor-pointer"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {/* {showConfirmPassword ? (
                      <AiOutlineEyeInvisible
                        size={25}
                        className="text-[gray]"
                      />
                    ) : (
                      <AiOutlineEye size={25} className="text-[gray]" />
                    )} */}
                  </div>
                </div>
                {errors.confirmPassword && (
                  <p className="text-red-500 text-xs">
                    {errors.confirmPassword}
                  </p>
                )}
                <div className="mb-4">
                  <span className="block text-sm font-medium text-gray-700 mb-2">
                    Gender
                  </span>
                  <div className="space-y-4">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        value="male"
                        checked={gender === "male"}
                        onChange={() => handleGenderChange("male")}
                      />
                      <span className="ml-2">Male</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        value="female"
                        checked={gender === "female"}
                        onChange={() => handleGenderChange("female")}
                      />
                      <span className="ml-2">Female</span>
                    </label>
                  </div>
                </div>
                {errors.gender && (
                  <p className="text-red-500 text-xs">{errors.gender}</p>
                )}
                <Select
                  value={team}
                  onChange={handleTeamChange}
                  placeholder="Select Team"
                  className="mb-4"
                >
                  {loading ? (
                    <Select.Option key="loading" value="" disabled>
                      Loading...
                    </Select.Option>
                  ) : (
                    teams.map((team) => (
                      <Select.Option key={team.id} value={team.name}>
                        {team.name}
                      </Select.Option>
                    ))
                  )}
                </Select>
                {errors.team && (
                  <p className="text-red-500 text-xs">{errors.team}</p>
                )}
              </div>

              {errors.general && (
                <p className="text-red-500 text-xs">{errors.general}</p>
              )}
              <div className="flex justify-end">
                <Button
                  type="secondary"
                  className="bg-[blue] text-[white] mt-4"
                  htmlType="submit"
                  loading={loading}
                >
                  Edit Teamlead
                </Button>
              </div>
            </form>
          </Grid>
        </Grid>
      </List>
    </>
  );
};

export default EditTeamlead;
