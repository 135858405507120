import React, { useState, useEffect, useMemo, useRef } from "react";
import { Table, Input, Button, Space, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const leaderboardApiUrl = process.env.REACT_APP_API_TesterStatistics;

const Leaderboard = () => {
  const { Title } = Typography;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const searchInput = useRef(null);

  useEffect(() => {
    fetchLeaderboardData();
  }, []);

  const fetchLeaderboardData = async () => {
    try {
      const response = await fetch(leaderboardApiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch leaderboard data");
      }
      const data = await response.json();
      setUsers(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const sortedUsers = useMemo(() => {
    return [...users].sort((a, b) => {
      if (sortOrder === "desc") {
        return b.otps_count - a.otps_count;
      } else {
        return a.otps_count - b.otps_count;
      }
    });
  }, [users, sortOrder]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            type="secondary"
            className="bg-[blue] text-[white]"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Ranking",
      dataIndex: "ranking",
      key: "ranking",
      width: "12%",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "12%",
      render: (_, user) => `${user.first_name} ${user.last_name}`,
      ...getColumnSearchProps("first_name"),
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "OTPs Generated",
      dataIndex: "valid_otps",
      key: "valid_otps",
      width: "15%",
      ...getColumnSearchProps("valid_otps"),
      sorter: (a, b) => a.valid_otps - b.valid_otps,
      sortOrder: sortOrder === "asc" ? "ascend" : "descend",
      render: (text) => (
        <span className="text-blue-500 text-lg font-semibold">{text}</span>
      ),
    },
  ];

  return (
    <div
      style={{
        width: "300px",
        position: "fixed",
        right: 0,
        top: 65,
        height: "100vh",
        overflowY: "auto",
        padding: "16px",
        backgroundColor: "#fff",
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
      }}
    >
      <Title level={4}>Leaderboard</Title>

      <Table
        columns={columns}
        dataSource={sortedUsers}
        rowKey="user_id"
        loading={loading}
        pagination={{ pageSize: 7 }}
        size="small"
      />
    </div>
  );
};

export default Leaderboard;
