import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Bar, Line } from "react-chartjs-2";
import { useTable } from "react-table";
import "chart.js/auto";
import styled from "styled-components";
import { TailSpin } from "react-loader-spinner";

// Flexbox container to hold both graphs and tables
const GraphTableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

// Container for individual graphs
const GraphContainer = styled.div`
  flex-basis: 49%;
  margin-bottom: 15px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
  @media (max-width: 900px) {
    width: 100%;
    height: auto;
  }
`;

// Container to ensure the chart takes all available space
const ChartContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

// Container for the table
const TableContainer = styled.div`
  flex-basis: 49%;
  margin-top: 0px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  height: 400px;
  @media (max-width: 900px) {
    width: 100%;
    height: auto;
    margin-top: 0;
  }
`;

// Header container to hold the title and date pickers
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

// Title styling
const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #343a40;
  margin: 0;
  @media (max-width: 600px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

// Container for date pickers and buttons
const DatePickerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

// Date picker styling
const StyledDatePicker = styled(DatePicker)`
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  margin: 5px;
  width: 150px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

// Fetch button styling
const FetchButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-left: 0;
    padding: 10px;
    margin-top: 10px;
  }
`;

// Chart type selector styling
const ChartTypeSelector = styled.select`
  margin-left: 10px;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 4px;

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 10px;
  }
`;

// Loading spinner container
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

// Table styling
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: 100%;
`;

// Table header styling
const StyledThead = styled.thead`
  background-color: #007bff;
  color: white;
`;

// Table header cell styling
const StyledTh = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

// Table row styling
const StyledTr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  &:hover {
    background-color: #ddd;
  }
`;

// Table data cell styling
const StyledTd = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

// Card container styling
const CardContainer = styled.div`
  flex-basis: 49%;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 15px;
  line-height: 1.5;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

// Flex container for the cards
const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const MyComponent = () => {
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  const [startDate, setStartDate] = useState(startOfMonth);
  const [endDate, setEndDate] = useState(currentDate);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chartType, setChartType] = useState("bar");

  const formatDateToUTCString = (date) => {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    )
      .toISOString()
      .split("T")[0];
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://qzufib1cx8.execute-api.us-east-1.amazonaws.com/dev/country-wise-otp-count",
        {
          start_date: formatDateToUTCString(startDate),
          end_date: formatDateToUTCString(endDate),
        }
      );
      const fetchedData = response.data;
      // Sort data by date
      fetchedData.mali_per_date.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );
      fetchedData.ghana_per_date.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );
      setData(fetchedData);
    } catch (error) {
      console.error("Error fetching data", error);
      setData(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const dates = useMemo(
    () => (data ? data.mali_per_date.map((entry) => entry.date) : []),
    [data]
  );

  const maliCounts = useMemo(
    () => (data ? data.mali_per_date.map((entry) => entry.count) : []),
    [data]
  );

  const ghanaCounts = useMemo(
    () => (data ? data.ghana_per_date.map((entry) => entry.count) : []),
    [data]
  );

  const chartData = {
    labels: dates,
    datasets: [
      {
        label: "Mali OTP Count",
        data: maliCounts,
        backgroundColor:
          chartType === "bar"
            ? "rgba(255, 99, 132, 0.6)"
            : "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        fill: chartType === "line",
      },
      {
        label: "Ghana OTP Count",
        data: ghanaCounts,
        backgroundColor:
          chartType === "bar"
            ? "rgba(54, 162, 235, 0.6)"
            : "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        fill: chartType === "line",
      },
    ],
  };

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Mali Count",
        accessor: "maliCount",
      },
      {
        Header: "Ghana Count",
        accessor: "ghanaCount",
      },
    ],
    []
  );

  const tableData = useMemo(
    () =>
      dates.map((date, index) => ({
        date,
        maliCount: maliCounts[index],
        ghanaCount: ghanaCounts[index],
      })),
    [dates, maliCounts, ghanaCounts]
  );

  const totalGhana = useMemo(
    () => (ghanaCounts ? ghanaCounts.reduce((acc, curr) => acc + curr, 0) : 0),
    [ghanaCounts]
  );

  const totalMali = useMemo(
    () => (maliCounts ? maliCounts.reduce((acc, curr) => acc + curr, 0) : 0),
    [maliCounts]
  );

  const tableInstance = useTable({ columns, data: tableData });

  return (
    <div>
      <HeaderContainer>
        <Title>Country Wise OTP Statistics</Title>
        <DatePickerContainer>
          <StyledDatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="yyyy-MM-dd"
            placeholderText="Start Date"
          />
          <StyledDatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat="yyyy-MM-dd"
            placeholderText="End Date"
          />
          <FetchButton onClick={fetchData}>Fetch Data</FetchButton>
          <ChartTypeSelector
            value={chartType}
            onChange={(e) => setChartType(e.target.value)}
          >
            <option value="bar">Bar Chart</option>
            <option value="line">Line Chart</option>
          </ChartTypeSelector>
        </DatePickerContainer>
      </HeaderContainer>

      <GraphTableContainer>
        <GraphContainer>
          <h2>{chartType === "bar" ? "Bar Chart" : "Line Chart"}</h2>
          <ChartContainer>
            {loading ? (
              <LoadingContainer>
                <TailSpin color="#007bff" height={80} width={80} />
              </LoadingContainer>
            ) : chartType === "bar" ? (
              <Bar data={chartData} />
            ) : (
              <Line data={chartData} />
            )}
          </ChartContainer>
        </GraphContainer>
        <TableContainer>
          <StyledTable {...tableInstance.getTableProps()}>
            <StyledThead>
              {tableInstance.headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <StyledTh {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </StyledTh>
                  ))}
                </tr>
              ))}
            </StyledThead>
            <tbody {...tableInstance.getTableBodyProps()}>
              {tableInstance.rows.map((row) => {
                tableInstance.prepareRow(row);
                return (
                  <StyledTr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <StyledTd {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </StyledTd>
                    ))}
                  </StyledTr>
                );
              })}
            </tbody>
          </StyledTable>
        </TableContainer>
      </GraphTableContainer>

      <CardsContainer>
        <CardContainer>Total OTPs Ghana: {totalGhana}</CardContainer>
        <CardContainer>Total OTPs Mali: {totalMali}</CardContainer>
      </CardsContainer>
    </div>
  );
};

export default MyComponent;
