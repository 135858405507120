import "./App.css";
import Login from "./components/login";
import Logout from "./components/logout";
import AddTester from "./components/team/addTeam";
import TeamLeadDashboard from "./components/teamleadDashboard";
import SuperAdminDashboard from "./components/superAdmin/superAdminDashboard";
import TesterDashboard from "./components/user/testerDashboard";
import ProjectHeadDashboard from "./components/admin/dashboard";
import SpecialUserDashboard from "./components/specialTester/specialTesterDashboard";
import SpecialTesterDashboard from "./components/internalSpecialTesters/specialTesterDashboard";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useEffect, useState } from "react";

function App() {
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  useEffect(() => {
    const checkSession = () => {
      const setupTime = localStorage.getItem("setupTime");
      if (setupTime) {
        const setupTimeMs = parseInt(setupTime, 10);
        const currentTime = Date.now();
        // 86400000 ms = 24 hours
        if (currentTime - setupTimeMs > 86400000) {
          setIsSessionExpired(true);
        }
      }
    };

    checkSession();
    const intervalId = setInterval(checkSession, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // const user = JSON.parse(localStorage.getItem("user"));
  //     if (user) {
  //       setRoleId(user.roleId);
  //     }
  const isUserLoggedIn = !!localStorage.getItem("user");
  console.log("isUserLoggedIn", isUserLoggedIn);
  // const role = localStorage.getItem("userRole") === "admin";

  return (
    <Router>
      <Routes>
        {isUserLoggedIn ? (
          isSessionExpired ? (
            <Route path="*" element={<Logout />} />
          ) : (
            <>
              <Route path="/add-testers" element={<AddTester />} />
              <Route path="/ad" element={<ProjectHeadDashboard />} />
              <Route path="/sa" element={<SuperAdminDashboard />} />
              <Route
                path="/teamleadDashboard"
                element={<TeamLeadDashboard />}
              />
              <Route path="/testerDashboard" element={<TesterDashboard />} />
              <Route
                path="/SpecialUserDashboard"
                element={<SpecialUserDashboard />}
              />
              <Route
                path="/SpecialTesterDashboard"
                element={<SpecialTesterDashboard />}
              />
              <Route path="*" element={<Logout />} />
            </>
          )
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
