import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Input,
  Space,
  Table,
  Layout,
  message,
  Alert,
  Typography,
  Grid,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const { Content, Sider } = Layout;
const { useBreakpoint } = Grid;
const apiUrl = process.env.REACT_APP_API_SMSIN;
const dataApiUrl = process.env.REACT_APP_OTPCountPerApp;
const appsWithTimestampUrl = process.env.REACT_APP_AppsWithTimestampUrl;

const FrequentApps = () => {
  const { Title } = Typography;
  const [messageApi, contextHolder] = message.useMessage();
  const [testers, setTesters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [filteredApps, setFilteredApps] = useState([]);
  const [appTimestamps, setAppTimestamps] = useState({});
  const screens = useBreakpoint();
  const searchInput = useRef(null);

  useEffect(() => {
    fetchData();
    fetchChartData();
    fetchAppTimestamps();
  }, []);

  const handleClose = () => {
    setTesters(null);
    setLoading(false);
  };

  const fetchData = async () => {
    const storedUser = localStorage.getItem("user");
    const user = JSON.parse(storedUser);
    try {
      const response = await fetch(`${apiUrl}/${user.userId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch smsin");
      }
      const data = await response.json();
      setTesters(data.body);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching smsin:", error);
    }
  };
  const fetchChartData = async () => {
    try {
      const response = await fetch(dataApiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch chart data");
      }
      const data = await response.json();

      // Log the data to check its structure
      console.log("Fetched data:", data);

      // Filter out entries with no data (undefined, null, or empty objects)
      const nonEmptyData = Object.entries(data)
        .filter(
          ([country, values]) =>
            values &&
            typeof values === "object" &&
            Object.keys(values).length > 0
        )
        .reduce((acc, [country, values]) => {
          acc[country] = values;
          return acc;
        }, {});

      // Log the non-empty data to check filtering
      console.log("Non-empty data:", nonEmptyData);

      // Format data for the chart
      const formattedData = Object.keys(nonEmptyData).map((country) => {
        return {
          country,
          ...nonEmptyData[country],
        };
      });

      setChartData(formattedData);

      // Pass the valid nonEmptyData to filterApps
      filterApps(nonEmptyData);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };
  const filterApps = (data) => {
    if (!data || typeof data !== "object") {
      console.error("Invalid data provided to filterApps");
      return;
    }

    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    const filtered = Object.entries(data).map(([country, apps]) => {
      if (!apps || typeof apps !== "object") {
        console.error(`Invalid apps data for country: ${country}`);
        return { country: capitalize(country), apps: [] };
      }

      const filteredApps = Object.entries(apps)
        .filter(([app, count]) => count > 70)
        .map(([app, count]) => ({ app, count }));

      return { country: capitalize(country), apps: filteredApps };
    });

    setFilteredApps(filtered);
  };

  const fetchAppTimestamps = async () => {
    try {
      const response = await fetch(appsWithTimestampUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch app timestamps");
      }
      const data = await response.json();
      setAppTimestamps(data);
    } catch (error) {
      console.error("Error fetching app timestamps:", error);
    }
  };

  // const filterApps = (data) => {
  //   const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  //   const filtered = ["ghana", "mali"].map((country) => {
  //     const apps = Object.entries(data[country])
  //       .filter(([app, count]) => count > 70)
  //       .map(([app, count]) => ({ app, count }));
  //     return { country: capitalize(country), apps };
  //   });
  //   setFilteredApps(filtered);
  // };

  const filteredTesters = testers.filter((message) => {
    const countryApps = appTimestamps[message.country];
    if (!countryApps) return true; // Include message if no apps data found for the country
    const appTimestamp = countryApps[message.sender_id];
    return (
      !appTimestamp || new Date(message.timestamp) <= new Date(appTimestamp)
    );
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            type="primary"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      width: "20%", // Increase width for better visibility on small screens
      ...getColumnSearchProps("timestamp"),
      sorter: (a, b) => a.timestamp.localeCompare(b.timestamp),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: "10%",
      ...getColumnSearchProps("country"),
      sorter: (a, b) => a.country.localeCompare(b.country),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Operator Name",
      dataIndex: "operator_name",
      key: "operator_name",
      width: "20%", // Increase width for better visibility on small screens
      ...getColumnSearchProps("operator_name"),
      sorter: (a, b) => a.operator_name.localeCompare(b.operator_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "B Number",
      dataIndex: "destination_number",
      key: "destination_number",
      width: "20%", // Increase width for better visibility on small screens
      ...getColumnSearchProps("destination_number"),
      sorter: (a, b) => a.destination_number - b.destination_number,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "A Number",
      dataIndex: "sender_id",
      key: "sender_id",
      width: "10%",
      ...getColumnSearchProps("sender_id"),
      sorter: (a, b) => a.sender_id - b.sender_id,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      width: "20%", // Increase width for better visibility on small screens
      ...getColumnSearchProps("content"),
      sorter: (a, b) => a.content - b.content,
      sortDirections: ["descend", "ascend"],
    },
  ];

  return (
    <Layout>
      {contextHolder}
      <Content
        style={{
          padding: screens.md ? "0 18px" : "0 5px", // Adjust padding for smaller screens
          minHeight: "100vh",
          background: "#fff",
        }}
      >
        <div className=" mb-4">
          <h2 className="text-2xl font-bold">Frequently Used Apps</h2>
        </div>
        {chartData.length > 0 && (
          <ResponsiveContainer
            width="100%"
            height={screens.md ? 400 : 250} // Adjust height for smaller screens
            debounce={1} // To improve performance and responsiveness
          >
            <BarChart
              data={chartData}
              margin={{
                top: 20,
                right: screens.md ? 30 : 5, // Adjust margins for smaller screens
                left: screens.md ? 20 : -20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="country" />
              <YAxis />
              <Tooltip />
              {screens.md ? <Legend /> : null}
              {Object.keys(chartData[0])
                .slice(1)
                .map((key) => (
                  <Bar
                    key={key}
                    dataKey={key}
                    fill={`#${Math.floor(Math.random() * 16777215).toString(
                      16
                    )}`}
                  />
                ))}
            </BarChart>
          </ResponsiveContainer>
        )}
      </Content>
    </Layout>
  );
};

export default FrequentApps;
