import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import styled from "styled-components";
import { TailSpin } from "react-loader-spinner";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";

const GraphContainer = styled.div`
  width: 90%;
  margin-bottom: 15px;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    width: 90%;
    margin: 3px;
  }
  @media (min-width: 900px) {
    width: 49%;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #343a40;
  margin: 0;
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const DatePickerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  margin: 5px;
  width: 150px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const FetchButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 600px) {
    width: 80%;
    margin-left: 0;
    padding: 10px;
    margin: 3px;
  }
`;

const ChartContainer = styled.div`
  height: 300px;
  width: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const DropdownContainer = styled.div`
  margin-left: 20px;
`;

const TotalOtpsCountGraph = ({ apiEndpoint, title }) => {
  // Calculate initial dates for the last seven days
  const today = new Date();
  const initialEndDate = new Date(today.setHours(23, 59, 59, 999));
  const initialStartDate = new Date(
    today.setDate(today.getDate() - 6) // Last 7 days including today
  );

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chartType, setChartType] = useState("bar");

  const fetchData = async () => {
    setLoading(true);
    const response = await axios.post(apiEndpoint, {
      start_date: startDate.toISOString().split("T")[0],
      end_date: endDate.toISOString().split("T")[0],
    });
    setChartData(formatDataForChart(response.data, title));
    setLoading(false);
  };

  const formatDataForChart = (data, title) => {
    return Object.keys(data.opt_count).map((key) => ({
      date: key,
      count: data.opt_count[key],
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChartTypeChange = (event) => {
    setChartType(event.target.value);
  };

  return (
    <GraphContainer>
      <TitleContainer>
        <Title>{title}</Title>
        <DropdownContainer>
          <FormControl variant="outlined" size="small">
            <InputLabel>Chart Type</InputLabel>
            <Select
              value={chartType}
              onChange={handleChartTypeChange}
              label="Chart Type"
            >
              <MenuItem value="line">Line Chart</MenuItem>
              <MenuItem value="bar">Bar Chart</MenuItem>
            </Select>
          </FormControl>
        </DropdownContainer>
      </TitleContainer>
      <DatePickerContainer>
        <StyledDatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
        />
        <StyledDatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
        />
        <FetchButton onClick={fetchData}>Submit</FetchButton>
      </DatePickerContainer>
      {loading ? (
        <LoadingContainer>
          <TailSpin
            height="80"
            width="80"
            color="#007bff"
            ariaLabel="loading"
          />
        </LoadingContainer>
      ) : (
        chartData.length > 0 && (
          <ChartContainer>
            <ResponsiveContainer width="100%" height="100%">
              {chartType === "line" ? (
                <LineChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="count"
                    stroke="#8884d8"
                    name="Total OTPs Generated by the Whole Team"
                  />
                </LineChart>
              ) : (
                <BarChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="count"
                    fill="#6a5acd"
                    name="Total OTPs Generated by the Whole Team"
                  />
                </BarChart>
              )}
            </ResponsiveContainer>
          </ChartContainer>
        )
      )}
    </GraphContainer>
  );
};

export default TotalOtpsCountGraph;
