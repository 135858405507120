import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();
  localStorage.removeItem("setupTime");
  localStorage.removeItem("user");
  navigate("/");
  window.location.reload();
  console.log("Logged out");
};

export default Logout;
