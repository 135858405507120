import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Alert,
  Typography,
  Form,
  message,
  Row,
  Col,
  Select,
  Card,
  Button,
  Modal,
  Empty,
  Radio,
  Layout,
  Grid,
  Spin,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import AllApplications from "./testSitesManually";
import { downloadCSV } from "./csvDownloader";
import Leaderboard from "./leaderboardSidebar";
import useLogout from "../useLogout";
const { Title } = Typography;
const { Option } = Select;
const { Content, Sider } = Layout;
const { useBreakpoint } = Grid;

const Testers = () => {
  const logout = useLogout();
  const countriesAPI = process.env.REACT_APP_API_UpdatedCountries;
  const mnoAPI = process.env.REACT_APP_API_MobileOperators;
  const simApiUrl = process.env.REACT_APP_API_SIMCards;
  const checkIfNumsAssigned = process.env.REACT_APP_API_CheckIfNumsAssigned;
  const specialCountriesAPI =
    "https://0id4y5c0xb.execute-api.us-east-1.amazonaws.com/dev";

  const [messageApi, contextHolder] = message.useMessage();
  const [countryList, setCountryList] = useState([]);
  const [mnoNameList, setMNONameList] = useState([]);
  const [country, setCountry] = useState("");
  const [mnoObject, setMnoObject] = useState(null);
  const [numbers, setNumbers] = useState([]);
  const [isSpecialUser, setIsSpecialUser] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [showNumbersCard, setShowNumbersCard] = useState(false);
  const [numbersAssigned, setNumbersAssigned] = useState(false);
  const [lockNumbersModalVisible, setLockNumbersModalVisible] = useState(false);
  const [noNumbersAvailable, setNoNumbersAvailable] = useState(false);
  const [numbersLocked, setNumbersLocked] = useState(false);
  const [lockedCountries, setLockedCountries] = useState([]);
  const [loadingNumbers, setLoadingNumbers] = useState(false);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [disabledCountries, setDisabledCountries] = useState({});
  const storedUser = localStorage.getItem("user");
  const user = JSON.parse(storedUser);
  const screens = useBreakpoint();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${checkIfNumsAssigned}/${user.userId}`
        );

        if (response.data.inactive) {
          logout();
        } else if (response.data.body && response.data.body.length >= 100) {
          console.log("condition met", response.data.body.length);
          setNumbersAssigned(true);
          setPhoneNumbers(response.data.body);
          const phoneNumbers = response.data.body.map((item) => item.msisdn);
          setNumbers(phoneNumbers);
          if (user.userId === 219) {
            setNumbersLocked(true);
          }
        } else if (response.data.body && response.data.body.length === 50) {
          console.log("else if met", response.data.body.length);
          // Make call to the special countries API
          const specialCountriesResponse = await axios.get(
            `${specialCountriesAPI}/${user.userId}`
          );
          const { Mali, Ghana } = specialCountriesResponse.data.body;

          // Set locked countries based on the response
          const locked = [];
          if (Mali) locked.push("Mali");
          if (Ghana) locked.push("Ghana");
          setLockedCountries(locked);
        } else {
          console.log("else", response.data.body.length);
          setLoadingCountries(true);
          const responseCountries = await axios.get(countriesAPI);
          setCountries(responseCountries.data.body);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingCountries(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (user.userId === 219) {
      setIsSpecialUser(true);
    }
    const fetchData = async () => {
      try {
        setLoadingCountries(true);
        const responseCountries = await axios.get(countriesAPI);
        setCountryList(responseCountries.data.body);
      } catch (error) {
        console.error("Error fetching countries data:", error);
      } finally {
        setLoadingCountries(false);
      }
    };
    fetchData();
  }, []);

  const handleCountryChange = async (e) => {
    const value = e.target.value;
    setCountry(value);
    fetchMNONameList(value);
    setShowNumbersCard(false);
  };

  const handleOperatorChange = async (value) => {
    const selectedMNO = mnoNameList.find((mno) => mno.id === value);
    setMnoObject(selectedMNO);
    setShowNumbersCard(true);
    fetchPhoneNumbers(selectedMNO.id);
  };

  const fetchMNONameList = async (selectedCountry) => {
    try {
      const dataUrl = `${mnoAPI}/?country=${selectedCountry}`;
      const response = await axios.get(dataUrl);
      setMNONameList(response.data.body);
    } catch (error) {
      console.error("Error fetching MNO names:", error);
    }
  };

  const fetchPhoneNumbers = async (mnoId) => {
    setLoadingNumbers(true);
    try {
      const response = await axios.get(
        `${simApiUrl}?mno=${mnoId}&tester_id=${user.userId}`
      );
      console.log("response from fetch number api", response.data.body);

      if (response.data.body.length === 0) {
        console.log("empty body");
        setNoNumbersAvailable(true);
      } else {
        setNoNumbersAvailable(false);
      }

      const phoneNumbers = response.data.body.map(
        (phoneNumber) => phoneNumber.msisdn
      );
      setNumbers(phoneNumbers);

      const numbers = response.data.body.map((phoneNumber) => phoneNumber.id);
      setSelectedNumbers(numbers);
    } catch (error) {
      console.error("Error fetching phone numbers:", error);
    } finally {
      setLoadingNumbers(false);
    }
  };

  const handleLockNumbersClick = async () => {
    try {
      if (!isSpecialUser && !disabledCountries[country]) {
        setDisabledCountries((prev) => ({ ...prev, [country]: true }));
        await axios.post(`${simApiUrl}?lock=true`, {
          user_id: user.userId,
          sims_id: selectedNumbers,
        });
        setNumbersLocked(true);
        setLockedCountries([...lockedCountries, country]); // Add country to lockedCountries
      }
      if (user.userId === 219) {
        downloadCSV(numbers);
      } else {
        setLockNumbersModalVisible(true);
      }
    } catch (error) {
      console.error("Error locking numbers:", error);
    }
  };

  const handleDownloadClick = () => {
    downloadCSV(numbers);
  };

  const handleSelectApplicationsClick = () => {
    setLockNumbersModalVisible(true);
  };

  return (
    <Layout>
      {contextHolder}
      <Content
        style={{ padding: "0 18px", minHeight: "100vh", background: "#fff" }}
      >
        {numbersAssigned ? (
          <Card
            title="Numbers Assigned To You for Today"
            className="numbers-card"
            extra={
              <Button
                type="secondary"
                className="lock-button bg-[blue] text-[white]"
                size="small"
                onClick={
                  isSpecialUser && numbersLocked
                    ? handleDownloadClick
                    : handleSelectApplicationsClick
                }
                disabled={disabledCountries[country]}
              >
                {isSpecialUser && numbersLocked
                  ? "Download CSV"
                  : isSpecialUser
                  ? "Download CSV"
                  : "Select Applications"}
              </Button>
            }
          >
            <ul>
              {numbers.map((number, index) => (
                <li key={index}>{number}</li>
              ))}
            </ul>
          </Card>
        ) : (
          <>
            {!numbersAssigned && (
              <>
                {!isSpecialUser && (
                  <Alert
                    message="Alert: If you don't click on Lock Numbers button, the numbers will not be locked for you and you will not be able to see the generated OTPs. Therefore make sure to click on Lock Numbers."
                    type="error"
                    banner
                  />
                )}
                <Title level={4}>Numbers Assignment</Title>
                <Row gutter={16}>
                  <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                    <Form.Item
                      label="Country"
                      required
                      tooltip={{
                        title: "This is a required field",
                        icon: <InfoCircleOutlined />,
                      }}
                    >
                      {loadingCountries ? (
                        <Spin size="small" />
                      ) : (
                        <Radio.Group
                          onChange={handleCountryChange}
                          value={country}
                          style={{ width: "100%" }}
                        >
                          {countryList.map((country) => (
                            <Radio.Button
                              key={country.id}
                              value={country.id}
                              disabled={lockedCountries.includes(country.name)}
                            >
                              {country.name}
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Form.Item
                      label="MNO Name"
                      required
                      tooltip={{
                        title: "This is a required field",
                        icon: <InfoCircleOutlined />,
                      }}
                    >
                      <Select
                        showSearch
                        placeholder="Select your MNO Name"
                        value={mnoObject?.id}
                        onChange={handleOperatorChange}
                        style={{ width: "100%" }}
                        disabled={!country}
                      >
                        {mnoNameList.map((mno) => (
                          <Option key={mno.id} value={mno.id}>
                            {mno.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {showNumbersCard && (
              <>
                {loadingNumbers ? (
                  <Spin tip="Loading...">
                    <Card
                      title="Numbers Assigned To You for Today"
                      className="numbers-card"
                      extra={
                        numbers.length > 0 && (
                          <Button
                            type="secondary"
                            className="lock-button bg-[blue] text-[white]"
                            size="small"
                            onClick={
                              isSpecialUser && numbersLocked
                                ? handleDownloadClick
                                : handleLockNumbersClick
                            }
                            disabled={disabledCountries[country]}
                          >
                            {isSpecialUser && numbersLocked
                              ? "Download CSV"
                              : "Lock Numbers"}
                          </Button>
                        )
                      }
                    >
                      <ul>
                        {numbers.map((number, index) => (
                          <li key={index}>{number}</li>
                        ))}
                      </ul>
                    </Card>
                  </Spin>
                ) : noNumbersAvailable ? (
                  <Empty
                    description="No numbers available for this country and network. Try a different country and network."
                    style={{ margin: "20px 0" }}
                  />
                ) : (
                  <Card
                    title="Numbers Assigned To You for Today"
                    className="numbers-card"
                    extra={
                      <Button
                        type="secondary"
                        className="lock-button bg-[blue] text-[white]"
                        size="small"
                        onClick={
                          isSpecialUser && numbersLocked
                            ? handleDownloadClick
                            : handleLockNumbersClick
                        }
                        disabled={disabledCountries[country]}
                      >
                        {isSpecialUser && numbersLocked
                          ? "Download CSV"
                          : isSpecialUser
                          ? "Download CSV"
                          : "Lock Numbers"}
                      </Button>
                    }
                  >
                    <ul>
                      {numbers.map((number, index) => (
                        <li key={index}>{number}</li>
                      ))}
                    </ul>
                  </Card>
                )}
              </>
            )}
          </>
        )}
        <Modal
          title="Select Applications"
          open={lockNumbersModalVisible}
          width={"100%"}
          footer={null}
          onCancel={() => setLockNumbersModalVisible(false)}
        >
          <AllApplications
            setShowSelectNumber={setLockNumbersModalVisible}
            numbers={numbers}
          />
        </Modal>
      </Content>
      {screens.md && (
        <Sider width={250} style={{ background: "#fff" }}>
          <Leaderboard />
        </Sider>
      )}
    </Layout>
  );
};

export default Testers;
