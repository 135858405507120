import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spin, Descriptions } from "antd";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

const ViewTeamLeadDetails = ({ setOpen, teamId }) => {
  const [teamleadData, setTeamleadData] = useState(null);
  const teamleadAPI = process.env.REACT_APP_API_Teamleads;

  useEffect(() => {
    const fetchTeamDetails = async () => {
      try {
        const response = await axios.get(`${teamleadAPI}/${teamId}`);
        setTeamleadData(response.data.body);
      } catch (error) {
        console.error("Error fetching team details:", error);
      }
    };

    fetchTeamDetails();
    return () => {
      setTeamleadData(null);
    };
  }, [teamId, teamleadAPI]);

  if (!teamleadData) {
    return <Spin />;
  }

  return (
    <>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            View Teamlead Details
          </Typography>
        </Toolbar>
      </AppBar>
      <List style={{ height: "50vh" }}>
        <Grid
          container
          style={{ height: "50%", justifyContent: "center" }}
          alignItems="center"
        >
          <Grid item xs={12} sm={8} md={6} lg={8}>
            <h2 className="text-2xl font-bold mt-8 mb-8">
              View Teamlead Details
            </h2>
            <Descriptions column={1} bordered>
              <Descriptions.Item label="Teamlead ID">
                {teamleadData.id}
              </Descriptions.Item>
              <Descriptions.Item label="Teamlead Name">
                {teamleadData.first_name + " " + teamleadData.last_name}
              </Descriptions.Item>
              <Descriptions.Item label="Contact Number">
                {teamleadData.contact_number}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {teamleadData.email}
              </Descriptions.Item>
            </Descriptions>
          </Grid>
        </Grid>
      </List>
    </>
  );
};

export default ViewTeamLeadDetails;
