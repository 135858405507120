import React, { useState, useEffect } from "react";
import axios from "axios";
import { Checkbox, Button, Row, Col, Divider } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

const AllApplications = ({ numbers }) => {
  const [sites, setSites] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_Applications
        );
        const apiSites = response.data.body.map((site) => ({
          id: site.id,
          name: site.name,
          selected: false,
        }));
        setSites(apiSites);
      } catch (error) {
        console.error("Error fetching sites from API:", error);
      }
    };

    fetchSites();
  }, []);

  const handleToggle = (id) => {
    setSites((prevSites) =>
      prevSites.map((site) =>
        site.id === id ? { ...site, selected: !site.selected } : site
      )
    );
  };

  const handleToggleAll = () => {
    setSelectAll(!selectAll);
    setSites((prevSites) =>
      prevSites.map((site) => ({
        ...site,
        selected: !selectAll,
      }))
    );
  };

  const handleDownloadCSV = () => {
    const selectedSites = sites.filter((site) => site.selected);
    const selectedSiteNames = selectedSites.map((site) => site.name);
    let csvData = `Number,${selectedSiteNames.join(",")}\n`;
    numbers.forEach((number) => {
      let rowData = `${number},`;
      selectedSites.forEach(() => {
        rowData += ",";
      });
      csvData += `${rowData}\n`;
    });
    const storedUser = localStorage.getItem("user");
    const user = JSON.parse(storedUser) || {};
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-GB");
    link.download = `${user.name}-${formattedDate}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className=" bg-[#FFFFFF]">
      <Row justify="end" className="mt-4 pr-10">
        <Col>
          <Checkbox checked={selectAll} onChange={handleToggleAll}>
            Select All
          </Checkbox>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]}>
        {sites.map((site) => (
          <Col key={site.id} xs={24} sm={12} md={8} lg={6} xl={4}>
            <Checkbox
              checked={site.selected}
              onChange={() => handleToggle(site.id)}
            >
              {site.name}
            </Checkbox>
          </Col>
        ))}
      </Row>
      <Row justify="end" className="mt-4 pr-1">
        <Button
          type="secondary"
          className="bg-[blue] text-[white]"
          s
          icon={<DownloadOutlined />}
          onClick={handleDownloadCSV}
        >
          Download as CSV
        </Button>
      </Row>
    </div>
  );
};

export default AllApplications;
