import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spin, Descriptions } from "antd";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
const ViewTesterDetails = ({ testerId, setOpen }) => {
  const [testerData, setTesterData] = useState(null);
  const apiUrl = process.env.REACT_APP_API_Testers;

  useEffect(() => {
    const fetchTesterDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/${testerId}`);
        setTesterData(response.data.body);
      } catch (error) {
        console.error("Error fetching tester details:", error);
      }
    };

    fetchTesterDetails();
    return () => {
      setTesterData(null);
    };
  }, [testerId]);

  if (!testerData) {
    return <Spin />;
  }

  return (
    <>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Tester Details
          </Typography>
        </Toolbar>
      </AppBar>
      <List style={{ height: "100vh" }}>
        <Grid container style={{ height: "100%", justifyContent: "center" }}>
          <Grid item xs={12} sm={8} md={6} lg={8}>
            <h2 className="text-2xl font-bold mt-8 mb-8">Tester Details</h2>
            <Descriptions column={2} bordered>
              <Descriptions.Item label="First Name">
                {testerData.first_name}
              </Descriptions.Item>
              <Descriptions.Item label="Last Name">
                {testerData.last_name}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {testerData.email}
              </Descriptions.Item>
              <Descriptions.Item label="Contact Number">
                {testerData.contact_number}
              </Descriptions.Item>
              <Descriptions.Item label="Gender">
                {testerData.gender}
              </Descriptions.Item>
              <Descriptions.Item label="Last Login">
                {testerData.last_login}
              </Descriptions.Item>
              <Descriptions.Item label="Bank Name">
                {testerData.bank_name}
              </Descriptions.Item>
              <Descriptions.Item label="Account title">
                {testerData.account_title}
              </Descriptions.Item>
              <Descriptions.Item label="Account Number">
                {testerData.account_number}
              </Descriptions.Item>
            </Descriptions>
          </Grid>
        </Grid>
      </List>
    </>
  );
};

export default ViewTesterDetails;
