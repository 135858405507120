import React, { useEffect, useState } from "react";
import {
  Button,
  Layout,
  Menu,
  Dropdown,
  Modal,
  Form,
  Input,
  theme,
  Tabs,
  Grid,
} from "antd";
import axios from "axios";
import Profile from "./profile";
import AddOTP from "./addotp";
import Inbox from "./inbox";
import Stats from "./stats";
import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useLogout from "../useLogout";

const { Header, Content, Footer } = Layout;
const { Item } = Form;
const { useBreakpoint } = Grid;
const dataApiUrl = process.env.REACT_APP_OTPCountPerApp;
const updatePassword = process.env.REACT_APP_API_UpdatePassword;
const teamStatsURL = process.env.REACT_APP_GrapStats;
const userStatsURL = process.env.REACT_APP_testertats;

const TesterDashboard = () => {
  const logout = useLogout();
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [form] = Form.useForm();
  const [filteredApps, setFilteredApps] = useState([]);
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const onLogout = () => {
    localStorage.removeItem("setupTime");
    localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
    console.log("Logout clicked");
  };

  const filterApps = (data) => {
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    const filtered = ["ghana", "mali"].map((country) => {
      const apps = Object.entries(data[country])
        .filter(([app, count]) => count > 70)
        .filter(([app]) => app !== "WhatsApp")
        .map(([app, count]) => ({ app, count }));
      return { country: capitalize(country), apps };
    });
    setFilteredApps(filtered);
  };

  const fetchChartData = async () => {
    try {
      const response = await fetch(dataApiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch chart data");
      }
      const data = await response.json();
      filterApps(data);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, []);

  const handlePasswordChange = () => {
    setChangePasswordVisible(true);
  };

  const handleCancel = () => {
    setChangePasswordVisible(false);
  };

  const handleFinish = async (values) => {
    try {
      const { currentPassword, newPassword } = values;
      const response = await axios.put(updatePassword, {
        userId: user.userId,
        oldPassword: currentPassword,
        newPassword: newPassword,
      });

      if (response.status === 200) {
        console.log("Password changed successfully");
        setChangePasswordVisible(false);
        logout();
      } else {
        console.error("Password change failed");
      }
    } catch (error) {
      console.error("Error changing password:", error);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="changePassword"
        icon={<SettingOutlined />}
        onClick={handlePasswordChange}
      >
        Change Password
      </Menu.Item>
      <Menu.Item key="logout" onClick={onLogout} icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", // Space between elements
          padding: "16px 24px",
          backgroundColor: "#001529",
        }}
      >
        <div style={{ color: "white", fontSize: "20px" }}>EON Testing Tool</div>
        {!screens.xs && (
          <div
            style={{
              color: "white",
              fontSize: "16px",
              display: "flex",
              overflow: "hidden",
              maxWidth: "60%",
            }}
          >
            {filteredApps.map((countryData, index) => (
              <div
                key={countryData.country}
                style={{
                  marginRight: index < filteredApps.length - 1 ? "10px" : "0",
                  whiteSpace: "nowrap",
                  fontSize: "calc(1rem - 0.1vw)", // Responsive font size
                }}
              >
                <strong>{countryData.country}:</strong>{" "}
                {countryData.apps.map((app, appIndex) => (
                  <span key={app.app}>
                    {app.app} ({app.count})
                    {appIndex < countryData.apps.length - 1 ? ", " : ""}
                  </span>
                ))}
              </div>
            ))}
          </div>
        )}
        <div>
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button type="link" style={{ color: "white" }}>
              {user.name
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </Button>
          </Dropdown>
        </div>
      </Header>
      {screens.xs && (
        <div
          style={{
            padding: "16px 24px",
            backgroundColor: "#f0f2f5",
            borderBottom: "1px solid #e8e8e8",
          }}
        >
          {filteredApps.map((countryData, index) => (
            <div
              key={countryData.country}
              style={{
                marginBottom: "10px",
                fontSize: "14px",
              }}
            >
              <strong>{countryData.country}:</strong>{" "}
              {countryData.apps.map((app, appIndex) => (
                <span key={app.app}>
                  {app.app} ({app.count})
                  {appIndex < countryData.apps.length - 1 ? ", " : ""}
                </span>
              ))}
            </div>
          ))}
        </div>
      )}
      <Content
        style={{
          padding: screens.xs ? "0 14px" : "0 48px",
        }}
      >
        <div
          style={{
            padding: screens.xs ? 3 : 24,
            minHeight: 380,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <View1 />
        </div>
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        EON Testing Tool ©{new Date().getFullYear()} Created by EON Intelligence
      </Footer>

      {/* Change Password Modal */}
      <Modal
        title="Change Password"
        visible={changePasswordVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          name="changePasswordForm"
          onFinish={handleFinish}
          layout="vertical"
        >
          <Item
            name="currentPassword"
            label="Current Password"
            rules={[
              {
                required: true,
                message: "Please input your current password!",
              },
            ]}
          >
            <Input.Password />
          </Item>
          <Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
            ]}
          >
            <Input.Password />
          </Item>
          <Item
            name="confirmNewPassword"
            label="Confirm New Password"
            dependencies={["newPassword"]}
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Item>
          <Item>
            <Button
              type="secondary"
              className="bg-[blue] text-[white]"
              htmlType="submit"
            >
              Change Password
            </Button>
          </Item>
        </Form>
      </Modal>
    </Layout>
  );
};

const View1 = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const screens = useBreakpoint();
  const onChangeTab = (key) => {
    setSelectedMenuTabItem(key);
  };
  const [tabPosition, setTabPosition] = useState("left");
  const [selectedMenuTabItem, setSelectedMenuTabItem] = useState("1");

  useEffect(() => {
    const handleResize = () => {
      // Switch to horizontal tabs for small screens
      if (window.innerWidth <= 768) {
        setTabPosition("top");
      } else {
        // Default to vertical tabs for larger screens
        setTabPosition("left");
      }
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        padding: 24,
        minHeight: 380,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <Tabs
        activeKey={selectedMenuTabItem}
        onChange={onChangeTab}
        type="card"
        tabPosition={tabPosition}
        style={{ minHeight: 200 }}
      >
        <Tabs.TabPane key="1" tab="Inbox">
          <Inbox />
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab="Dashboard">
          <Profile />
        </Tabs.TabPane>
        <Tabs.TabPane key="3" tab="Run Test">
          <AddOTP />
        </Tabs.TabPane>
        <Tabs.TabPane key="4" tab="Team Statistics">
          <Stats
            title="Total OTPs generated by the whole Team"
            apiUrl={teamStatsURL}
            userSpecific={false}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="5" tab="Your Statistics">
          <Stats
            title="Your Statistics"
            apiUrl={userStatsURL}
            userSpecific={true}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default TesterDashboard;
