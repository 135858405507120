// useLogout.js
import { useNavigate } from "react-router-dom";

const useLogout = () => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("setupTime");
    localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
    console.log("Logged out");
  };

  return logout;
};

export default useLogout;
