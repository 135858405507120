import React, { useState, useEffect, useMemo } from "react";
import {
  message,
  Typography,
  Card,
  Col,
  Row,
  Statistic,
  Alert,
  Layout,
  Grid,
} from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import Leaderboard from "./leaderboardSidebar";
import LeaderBoardComp from "../tester-teamlead/leaderBoard";
import useLogout from "../useLogout";

const { Content, Sider } = Layout;
const { useBreakpoint } = Grid;

const dailyOTPCount = process.env.REACT_APP_API_dialyOTPs;
const monthlyOTPCount = process.env.REACT_APP_API_MonthlyOTPs;
const previousMonthOTPCount = process.env.REACT_APP_API_PreviousMonthCount;
const duplicateMonthlyCount = process.env.REACT_APP_API_DuplicateOTPs;
const configSettingAPI = process.env.REACT_APP_ConfigSetting;

const Testers = () => {
  const { Title } = Typography;
  const [messageApi, contextHolder] = message.useMessage();
  const [dailyCount, setDailyCount] = useState(0);
  const [monthlyCount, setMonthlyCount] = useState(0);
  const [previousMonthCount, setPreviousMonthCount] = useState(0);
  const [duplicateMonthCount, setDuplicateMonthCount] = useState(0);
  const [bonusUSD, setBonusUSD] = useState();
  const [lowerLimitPayout, setLowerLimitPayout] = useState();
  const [loading, setLoading] = useState(true);
  const [minChargesPerMonthUSD, setMinChargesPerMonthUSD] = useState(35.99);

  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : {};

  const screens = useBreakpoint();

  const logout = useLogout();

  useEffect(() => {
    fetchOTPData();
  }, []);

  const fetchOTPData = async () => {
    try {
      const userId = user.userId;

      // Fetch daily count
      const dailyResponse = await fetch(`${dailyOTPCount}/${userId}`);
      const dailyData = await dailyResponse.json();

      if (dailyData.inactive) {
        logout();
      } else {
        setDailyCount(dailyData.otp_today);
      }

      // Fetch previous month's count
      const previousMonthCountResponse = await fetch(
        `${previousMonthOTPCount}/${userId}`
      );
      const prevMonthData = await previousMonthCountResponse.json();

      if (prevMonthData.inactive) {
        logout();
      } else {
        setPreviousMonthCount(prevMonthData.otp_current_month);
      }

      // Fetch monthly count
      const monthlyResponse = await fetch(`${monthlyOTPCount}/${userId}`);
      const monthlyData = await monthlyResponse.json();
      if (monthlyData.inactive) {
        logout();
      } else {
        setMonthlyCount(monthlyData.otp_current_month);
      }
      const monthlyDuplicateResponse = await fetch(
        `${duplicateMonthlyCount}/${userId}`
      );
      const monthlyDuplicateData = await monthlyDuplicateResponse.json();
      if (monthlyDuplicateData.inactive) {
        logout();
      } else {
        setDuplicateMonthCount(
          monthlyDuplicateData.duplicate_otp_current_month
        );
      }
      // Fetch configuration settings
      const configSettingResponse = await fetch(configSettingAPI);
      const configData = await configSettingResponse.json();
      const { bonus_usd, lower_limit_payout } = configData[0];
      setBonusUSD(bonus_usd);
      setLowerLimitPayout(lower_limit_payout || 0); // Ensure lower_limit_payout is defined
    } catch (error) {
      console.error("Error fetching OTP data:", error);
    }
  };

  const calculateValidOTPsAndPayment = (monthlyCount, duplicateMonthCount) => {
    const validOTPs = monthlyCount - duplicateMonthCount;
    let payment = validOTPs * 0.01;

    return { validOTPs, payment };
  };

  const { validOTPs, payment } = useMemo(
    () => calculateValidOTPsAndPayment(monthlyCount, duplicateMonthCount),
    [monthlyCount, duplicateMonthCount]
  );

  return (
    <Layout>
      {contextHolder}
      <Content
        style={{ padding: "0 18px", minHeight: "100vh", background: "#fff" }}
      >
        <Title level={3}>Tester Statistics</Title>

        {payment < minChargesPerMonthUSD ? (
          <Alert
            message={`If your valid OTP count for the month is less than 5000, the payment for it will be sent next month`}
            banner
            style={{ marginBottom: "16px" }}
          />
        ) : null}
        <Alert
          message={`Failure to maintain a monthly OTP count of 5000 for three consecutive months will result in account suspension.`}
          banner
          style={{ marginBottom: "16px" }}
        />

        <Row gutter={[16, 16]} justify="center">
          <Col xs={24}>
            <Card
              title="Your Profile"
              bordered={false}
              style={{
                border: "1px solid #d8d8d8",
                borderRadius: "8px",
              }}
              bodyStyle={{ padding: 20 }}
            >
              <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
                <Col xs={24} sm={12} md={6}>
                  <Card
                    bordered={false}
                    style={{
                      border: "1px solid #d8d8d8",
                      borderRadius: "8px",
                      height: screens.xs ? "auto" : "8.5rem",
                    }}
                  >
                    <Statistic
                      title="Total Received Tests Today"
                      value={dailyCount}
                      valueStyle={{ color: "#3f8600" }}
                      prefix={<ArrowUpOutlined />}
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Card
                    bordered={false}
                    style={{
                      border: "1px solid #d8d8d8",
                      borderRadius: "8px",
                      height: screens.xs ? "auto" : "8.5rem",
                    }}
                  >
                    <Statistic
                      title="Total Received Tests this Month"
                      value={monthlyCount}
                      valueStyle={{ color: "#3f8600" }}
                      prefix={<ArrowUpOutlined />}
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Card
                    bordered={false}
                    style={{
                      border: "1px solid #d8d8d8",
                      borderRadius: "8px",
                      height: screens.xs ? "auto" : "8.5rem",
                    }}
                  >
                    <Statistic
                      title="Total Duplicate Tests this Month"
                      value={duplicateMonthCount}
                      valueStyle={{ color: "#cf1322" }}
                      prefix={<ArrowDownOutlined />}
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Card
                    bordered={false}
                    style={{
                      border: "1px solid #d8d8d8",
                      borderRadius: "8px",
                      height: screens.xs ? "auto" : "8.5rem",
                    }}
                  >
                    <Statistic
                      title="Total Valid Tests this Month"
                      value={validOTPs}
                      valueStyle={{ color: "#3f8600" }}
                      prefix={<ArrowUpOutlined />}
                    />
                  </Card>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
                <Col xs={24} sm={12} md={8}>
                  <Card
                    bordered={false}
                    style={{
                      border: "1px solid #d8d8d8",
                      borderRadius: "8px",
                      height: screens.xs ? "auto" : "9.4rem",
                    }}
                  >
                    <Statistic
                      title="Payment for this month"
                      value={payment}
                      precision={2}
                      valueStyle={{ color: "#3f8600" }}
                      suffix="$(USD)"
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Card
                    bordered={false}
                    style={{
                      border: "1px solid #d8d8d8",
                      borderRadius: "8px",
                      height: screens.xs ? "auto" : "9.4rem",
                    }}
                  >
                    <Statistic
                      title="Received Tests For Previous Month"
                      value={previousMonthCount}
                      valueStyle={{ color: "#FFC000" }}
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Card
                    bordered={false}
                    style={{
                      border: "1px solid #d8d8d8",
                      borderRadius: "8px",
                      height: screens.xs ? "auto" : "9.4rem",
                    }}
                  >
                    <Statistic
                      title={"Per OTP Payment Rate"}
                      value={
                        "New Rate: 0.01 dollars per OTP (Payment will be made ONLY to those generating minimum of 5000 OTPs per month)"
                      }
                      precision={2}
                      valueStyle={{ color: "#3f8600", fontSize: "15px" }}
                    />
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        {!screens.md && (
          <div style={{ marginTop: "16px" }}>
            <LeaderBoardComp />
          </div>
        )}
      </Content>

      {screens.md && (
        <Sider width={250} style={{ background: "#fff" }}>
          <Leaderboard />
        </Sider>
      )}
    </Layout>
  );
};

export default Testers;
