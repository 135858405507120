import React, { useState, useEffect } from "react";
import axios from "axios";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import { Input, Select } from "antd";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

const AddTeam = ({ setOpen }) => {
  const countryAPI = process.env.REACT_APP_API_Countries;
  const teamsURL = process.env.REACT_APP_API_Team;

  const [name, setName] = useState("");
  const [country_id, setCountry_id] = useState(undefined);
  const [errors, setErrors] = useState({
    name: "",
    country_id: "",
    general: "",
  });
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    setLoading(true);
    try {
      const response = await axios.get(countryAPI);

      setCountries(response.data.body);
    } catch (error) {
      console.error("Error fetching countries:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTeamNameChange = (e) => {
    setName(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
  };

  const handleCountryChange = (value) => {
    setCountry_id(value);
    setErrors((prevErrors) => ({ ...prevErrors, country_id: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!name.trim()) {
      newErrors.name = "Please enter the team name";
      newErrors.country_id = "Please select the country";
      setErrors(newErrors);
      return;
    }
    if (!name.trim()) {
      newErrors.name = "Please enter the team name";
      setErrors(newErrors);
      return;
    }
    if (!country_id) {
      newErrors.country_id = "Please select the country";
      setErrors(newErrors);
      return;
    }

    try {
      const response = await axios.post(teamsURL, { name, country_id });
      if (response.status === 200) {
        setOpen(false);
      } else {
        throw new Error("Failed to add team");
      }
    } catch (error) {
      console.error("Error adding team:", error);
      setErrors({ general: "Failed to add team" });
    }
  };

  return (
    <>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Add Team
          </Typography>
        </Toolbar>
      </AppBar>
      <List style={{ height: "100vh" }}>
        <Grid
          container
          style={{ height: "100%", justifyContent: "center" }}
          alignItems="center"
        >
          <Grid item xs={12} sm={8} md={6} lg={8}>
            <form onSubmit={handleSubmit} className="mx-10">
              <div className=" mb-4">
                <h2 className="text-2xl font-bold">Add Team</h2>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <Input
                  value={name}
                  onChange={handleTeamNameChange}
                  placeholder="Enter Team Name"
                  addonBefore="Enter Team Name"
                  className="mb-4"
                />
                <Select
                  placeholder="Select Country"
                  value={country_id}
                  onChange={handleCountryChange} // pass the value directly
                  className="mb-4"
                >
                  {countries.map((country_id) => (
                    <Select.Option key={country_id.id} value={country_id.id}>
                      {country_id.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white] ml-2"
                >
                  Add Team
                </button>
              </div>
            </form>
          </Grid>
        </Grid>
      </List>
    </>
  );
};

export default AddTeam;
