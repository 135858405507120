import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import { Bar, Line } from "react-chartjs-2";
import { useTable } from "react-table";
import "chart.js/auto";
import styled from "styled-components";
import { TailSpin } from "react-loader-spinner";

// Flexbox container to hold both graphs and tables
const GraphTableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

// Container for individual graphs
const GraphContainer = styled.div`
  flex-basis: 49%;
  margin-bottom: 15px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px; /* Slightly more rounded corners for a modern look */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Softer shadow for a sleeker appearance */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px; /* Ensure it takes the full height */
  @media (max-width: 900px) {
    width: 100%;
    height: auto; /* Adjust height for smaller screens */
  }
`;

// Container to ensure the chart takes all available space
const ChartContainer = styled.div`
  flex: 1; /* Ensure it takes all available space */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

// Container for the table
const TableContainer = styled.div`
  flex-basis: 49%;
  margin-top: 0px;
  background: #ffffff;
  border-radius: 10px; /* More rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Softer shadow */
  overflow-x: auto;
  height: 400px; /* Set fixed height */
  @media (max-width: 900px) {
    width: 100%;
    height: auto; /* Adjust height for smaller screens */
    margin-top: 0;
  }
`;

// Header container to hold the title and date pickers
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

// Title styling
const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #343a40;
  margin: 0;
  @media (max-width: 600px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

// Chart type selector styling
const ChartTypeSelector = styled.select`
  margin-left: 10px;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 4px;

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 10px;
  }
`;

// Loading spinner container
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust height to fill the container */
`;

// Table styling
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: 100%; /* Adjust height to fill the container */
`;

// Table header styling
const StyledThead = styled.thead`
  background-color: #007bff;
  color: white;
`;

// Table header cell styling
const StyledTh = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

// Table row styling
const StyledTr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  &:hover {
    background-color: #ddd;
  }
`;

// Table data cell styling
const StyledTd = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

const MyComponent = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chartType, setChartType] = useState("bar");

  const fetchMonthlyData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://qzufib1cx8.execute-api.us-east-1.amazonaws.com/dev/month-wise-otp-count"
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchMonthlyData();
  }, []);

  const months = useMemo(
    () => (data ? data.map((item) => item.month_name) : []),
    [data]
  );

  const counts = useMemo(
    () => (data ? data.map((item) => item.count) : []),
    [data]
  );

  const chartData = {
    labels: months,
    datasets: [
      {
        label: "OTP Count",
        data: counts,
        backgroundColor: "rgba(153, 102, 255, 0.6)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
      },
    ],
  };

  const columns = useMemo(
    () => [
      {
        Header: "Month Name",
        accessor: "month_name",
      },
      {
        Header: "OTP Count",
        accessor: "count",
      },
    ],
    []
  );

  const tableData = useMemo(() => (data ? data : []), [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableData });

  return (
    <div>
      <HeaderContainer>
        <Title>Month-wise OTP Statistics</Title>
        <ChartTypeSelector
          value={chartType}
          onChange={(e) => setChartType(e.target.value)}
        >
          <option value="bar">Bar Chart</option>
          <option value="line">Line Chart</option>
        </ChartTypeSelector>
      </HeaderContainer>
      <GraphTableContainer>
        <GraphContainer>
          {loading ? (
            <LoadingContainer>
              <TailSpin
                height="80"
                width="80"
                color="#007bff"
                ariaLabel="loading"
              />
            </LoadingContainer>
          ) : (
            <ChartContainer>
              {chartType === "bar" ? (
                <Bar
                  data={chartData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: { legend: { position: "top" } },
                  }}
                />
              ) : (
                <Line
                  data={chartData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: { legend: { position: "top" } },
                  }}
                />
              )}
            </ChartContainer>
          )}
        </GraphContainer>
        <TableContainer>
          <StyledTable {...getTableProps()}>
            <StyledThead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <StyledTh {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </StyledTh>
                  ))}
                </tr>
              ))}
            </StyledThead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <StyledTr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <StyledTd {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </StyledTd>
                    ))}
                  </StyledTr>
                );
              })}
            </tbody>
          </StyledTable>
        </TableContainer>
      </GraphTableContainer>
    </div>
  );
};

export default MyComponent;
