import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Space, Table, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const apiUrl = process.env.REACT_APP_API_Teamlead_Testers;
const toggleUrl = "https://i9qlv36tdb.execute-api.us-east-1.amazonaws.com/dev/";

const Testers = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [testers, setTesters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(true);
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleClose = () => {
    setTesters(null);
    setOpen(false);
  };
  const handleToggleStatus = async (id, currentStatus) => {
    try {
      if (!toggleUrl) {
        throw new Error("Toggle URL is not defined");
      }

      const updatedStatus = currentStatus === 0 ? 1 : 0;
      const url = `${toggleUrl}`;

      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: id }),
      });

      if (!response.ok) {
        throw new Error("Failed to toggle status");
      }

      fetchData();
      messageApi.success(
        `User ${
          updatedStatus === 0 ? "activated" : "inactivated"
        } successfully!`
      );
    } catch (error) {
      console.error("Error toggling status:", error);
      messageApi.error("Failed to toggle status");
    }
  };

  const storedUser = localStorage.getItem("user");
  const user = JSON.parse(storedUser);
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/${user.userId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch testers");
      }
      const data = await response.json();
      setTesters(data.body);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching testers:", error);
    } finally {
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />

        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            type="secondary"
            className="bg-[blue] text-[white]"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "12%",
      ...getColumnSearchProps("first_name"),
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "10%",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: "15%",
      ...getColumnSearchProps("gender"),
      sorter: (a, b) => a.gender.localeCompare(b.gender),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      key: "last_login",
      width: "15%",
      ...getColumnSearchProps("last_login"),
      sorter: (a, b) => a.last_login - b.last_login,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      key: "status",
      width: "15%",
      render: (_, record) => (
        <Button
          type={record.inactive === 0 ? "danger" : "primary"}
          style={{
            borderColor: record.inactive === 0 ? "red" : "#1677ff",
            color: record.inactive === 0 ? "#fff" : "#fff",
            backgroundColor: record.inactive === 0 ? "red" : "#1677ff",
          }}
          onClick={() => handleToggleStatus(record.id, record.inactive)}
        >
          {record.inactive === 0 ? "Inactivate" : "Activate"}
        </Button>
      ),
    },
  ];

  return (
    <>
      {contextHolder}

      {testers && (
        <>
          <Table
            columns={columns}
            dataSource={testers}
            loading={loading}
            locale={{
              emptyText: loading ? "Loading..." : "No Data",
            }}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 5,
              pageSizeOptions: ["5", "10", "20"],
            }}
            scroll={{ x: "100%" }}
          />
        </>
      )}
    </>
  );
};

export default Testers;
