import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/charts";
import axios from "axios";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Row, Col } from "antd";

const DemoColumn = ({ id }) => {
  const [data, setData] = useState([]);
  const [selectedRange, setSelectedRange] = useState([new Date(), new Date()]);

  const apiUrl = process.env.REACT_APP_testertats;

  useEffect(() => {
    const fetchData = async () => {
      if (selectedRange.length !== 2) {
        return;
      }
      try {
        const response = await axios.post(apiUrl, {
          start_date: selectedRange[0].toISOString().split("T")[0],
          end_date: selectedRange[1].toISOString().split("T")[0],
          tester_id: id,
        });
        const optCount = response.data.opt_count;

        // Process data
        const currentWeekDates = [];
        const currentDateFormatted = new Date(selectedRange[0]);
        while (currentDateFormatted <= selectedRange[1]) {
          currentWeekDates.push(
            currentDateFormatted.toISOString().split("T")[0]
          );
          currentDateFormatted.setDate(currentDateFormatted.getDate() + 1);
        }

        const newData = currentWeekDates.map((date) => ({
          type: date,
          value: optCount[date] !== undefined ? optCount[date] : null,
        }));

        setData(newData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedRange, id]); // Include id in the dependency array

  const handleDateChange = (ranges) => {
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;
    setSelectedRange([startDate, endDate]);
  };

  const config = {
    data,
    xField: "type",
    yField: "value",
    xAxis: {
      label: {
        formatter: (val) => {
          const date = new Date(val);
          const day = date.getDate();
          const month = date.getMonth() + 1; // Months are zero indexed, so we add 1
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        },
      },
    },
    style: {
      fill: ({ type }) => {
        if (type === "10-30" || type === "30") {
          return "#22CBCC";
        }
        return "#2989FF";
      },
    },
    label: {
      text: (originData) => {
        const val = parseFloat(originData.value);
        if (val < 0.05) {
          return (val * 100).toFixed(1);
        }
        return "";
      },
      offset: 10,
    },
    legend: false,
  };

  return (
    <div className="flex flex-col md:flex-row justify-center items-center md:items-start p-4 space-y-4 md:space-y-0 md:space-x-4">
      {/* Mobile: Stacked vertically; Desktop: Horizontal layout with top margin */}
      <div className="w-full md:w-2/5 md:overflow-visible overflow-x-auto md:mt-4">
        <div className="min-w-max">
          <DateRangePicker
            onChange={handleDateChange}
            moveRangeOnFirstSelection={false}
            ranges={[
              {
                startDate: selectedRange[0],
                endDate: selectedRange[1],
                key: "selection",
              },
            ]}
            className="w-full"
          />
        </div>
      </div>
      <div className="w-full md:w-1/3 md:mt-4">
        <Column {...config} />
      </div>
    </div>
  );
};

export default DemoColumn;
