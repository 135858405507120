import React, { useState, useEffect } from "react";
import { Spin, Descriptions } from "antd";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";

const ViewSimDetails = ({ setOpen, id }) => {
  const [apiData, setApiData] = useState(null);
  const simCardsApi = process.env.REACT_APP_API_SIMCards;

  useEffect(() => {
    if (id) {
      const dataUrl = `${simCardsApi}/${id}`;
      fetch(dataUrl)
        .then((res) => res.json())
        .then((res) => {
          setApiData(res.body);
        });
    }
  }, [id]);

  if (!apiData) {
    return <Spin />;
  }

  return (
    <>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{ marginLeft: "2rem", flex: 1 }}>
            View Sim Details
          </Typography>
        </Toolbar>
      </AppBar>
      <List style={{ height: "50vh" }}>
        <Grid
          container
          style={{ height: "50%", justifyContent: "center" }}
          alignItems="center"
        >
          <Grid item xs={12} sm={8} md={6} lg={8}>
            <h2 className="text-2xl font-bold mt-8 mb-8">View SIM Details</h2>
            <Descriptions column={1} bordered>
              <Descriptions.Item label="MSISDN">
                {apiData.msisdn}
              </Descriptions.Item>
              <Descriptions.Item label="Operator">
                {apiData.operator_name}
              </Descriptions.Item>
              <Descriptions.Item label="Country">
                {apiData.country_name}
              </Descriptions.Item>

              <Descriptions.Item label="Country Code(Dailing Code)">
                {apiData.dialing_code}
              </Descriptions.Item>
            </Descriptions>
          </Grid>
        </Grid>
      </List>
    </>
  );
};

export default ViewSimDetails;
