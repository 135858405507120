import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Bar, Line } from "react-chartjs-2";
import { useTable } from "react-table";
import "chart.js/auto";
import styled from "styled-components";
import { TailSpin } from "react-loader-spinner";

// Flexbox container to hold both graphs and tables
const GraphTableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

// Container for individual graphs
const GraphContainer = styled.div`
  flex-basis: 49%;
  margin-bottom: 15px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px; /* Slightly more rounded corners for a modern look */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Softer shadow for a sleeker appearance */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px; /* Ensure it takes the full height */
  @media (max-width: 900px) {
    width: 100%;
    height: auto; /* Adjust height for smaller screens */
  }
`;

// Container to ensure the chart takes all available space
const ChartContainer = styled.div`
  flex: 1; /* Ensure it takes all available space */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

// Container for the table
const TableContainer = styled.div`
  flex-basis: 49%;
  margin-top: 0px;
  background: #ffffff;
  border-radius: 10px; /* More rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Softer shadow */
  overflow-x: auto;
  height: 400px; /* Set fixed height */
  @media (max-width: 900px) {
    width: 100%;
    height: auto; /* Adjust height for smaller screens */
    margin-top: 0;
  }
`;

// Header container to hold the title and date pickers
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

// Title styling
const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #343a40;
  margin: 0;
  @media (max-width: 600px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

// Container for date pickers and buttons
const DatePickerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

// Date picker styling
const StyledDatePicker = styled(DatePicker)`
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  margin: 5px;
  width: 150px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

// Fetch button styling
const FetchButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-left: 0;
    padding: 10px;
    margin-top: 10px;
  }
`;

// Chart type selector styling
const ChartTypeSelector = styled.select`
  margin-left: 10px;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 4px;

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 10px;
  }
`;

// Loading spinner container
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust height to fill the container */
`;

// Table styling
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: 100%; /* Adjust height to fill the container */
`;

// Table header styling
const StyledThead = styled.thead`
  background-color: #007bff;
  color: white;
`;

// Table header cell styling
const StyledTh = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

// Table row styling
const StyledTr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  &:hover {
    background-color: #ddd;
  }
`;

// Table data cell styling
const StyledTd = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

// Card container styling
const CardContainer = styled.div`
  flex-basis: 49%; /* Ensure cards are side by side */
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px; /* More rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Softer shadow */
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 15px; /* Spacing between cards */
  line-height: 1.5; /* Added line-height for better spacing */

  @media (max-width: 900px) {
    width: 100%;
  }
`;

// Flex container for the cards
const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const MyComponent = () => {
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  const [startDate, setStartDate] = useState(startOfMonth);
  const [endDate, setEndDate] = useState(currentDate);
  const [data, setData] = useState(null);
  const [averageOtps, setAverageOtps] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chartType, setChartType] = useState("bar");

  // States for initial card values
  const [initialTotalValidOtps, setInitialTotalValidOtps] = useState(0);
  const [initialAverageOtps, setInitialAverageOtps] = useState(0);

  const formatDateToUTCString = (date) => {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    )
      .toISOString()
      .split("T")[0];
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://fbpsjefhv4.execute-api.us-east-1.amazonaws.com/dev/per-team-billing",
        {
          start_date: formatDateToUTCString(startDate),
          end_date: formatDateToUTCString(endDate),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data", error);
      return null;
    }
  };

  const fetchAverageOtps = async () => {
    try {
      const response = await axios.post(
        "https://fbpsjefhv4.execute-api.us-east-1.amazonaws.com/dev/all-otps-day-wise"
      );
      return response.data.estimated_remaining_otps;
    } catch (error) {
      console.error("Error fetching average OTPs", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      try {
        const [initialData, avgOtps] = await Promise.all([
          fetchData(),
          fetchAverageOtps(),
        ]);
        setData(initialData);
        setAverageOtps(avgOtps);

        // Set initial card values only once
        setInitialTotalValidOtps(initialData.total_valid_otps || 0);
        setInitialAverageOtps(avgOtps || 0);
      } catch (error) {
        console.error("Error fetching data", error);
      }
      setLoading(false);
    };
    fetchInitialData();
  }, []);

  const updateData = async () => {
    setLoading(true);
    try {
      const newData = await fetchData();
      setData(newData);
    } catch (error) {
      console.error("Error fetching data", error);
    }
    setLoading(false);
  };

  const teams = useMemo(
    () =>
      Object.values(data || {})
        .filter(
          (team) =>
            team.calculated_otps_cost !== undefined &&
            team.calculated_otps_cost !== 0
        )
        .sort((a, b) => b.calculated_otps_cost - a.calculated_otps_cost),
    [data]
  );

  const teamNames = useMemo(() => teams.map((team) => team.team_name), [teams]);
  const otpsCosts = useMemo(
    () => teams.map((team) => parseFloat(team.calculated_otps_cost.toFixed(2))),
    [teams]
  );

  const chartData = {
    labels: teamNames,
    datasets: [
      {
        label: "Calculated OTP Cost",
        data: otpsCosts,
        backgroundColor: "rgba(153, 102, 255, 0.6)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
      },
    ],
  };

  const columns = useMemo(
    () => [
      {
        Header: "Team Name",
        accessor: "team_name",
      },
      {
        Header: "OTP Count",
        accessor: "date_range_otps_count",
      },
      {
        Header: "Calculated OTP Cost",
        accessor: "calculated_otps_cost",
        Cell: ({ value }) => `$${parseFloat(value).toFixed(2)}`,
      },
    ],
    []
  );

  const tableData = useMemo(() => teams, [teams]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableData });

  return (
    <div>
      <HeaderContainer>
        <Title>Team-wise OTP Statistics Overview</Title>
        <DatePickerContainer>
          <StyledDatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
          <StyledDatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
          />
          <FetchButton onClick={updateData}>Submit</FetchButton>
          <ChartTypeSelector
            value={chartType}
            onChange={(e) => setChartType(e.target.value)}
          >
            <option value="bar">Bar Chart</option>
            <option value="line">Line Chart</option>
          </ChartTypeSelector>
        </DatePickerContainer>
      </HeaderContainer>
      <GraphTableContainer>
        <GraphContainer>
          {loading ? (
            <LoadingContainer>
              <TailSpin
                height="80"
                width="80"
                color="#007bff"
                ariaLabel="loading"
              />
            </LoadingContainer>
          ) : (
            <ChartContainer>
              {chartType === "bar" ? (
                <Bar
                  data={chartData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: { legend: { position: "top" } },
                  }}
                />
              ) : (
                <Line
                  data={chartData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: { legend: { position: "top" } },
                  }}
                />
              )}
            </ChartContainer>
          )}
        </GraphContainer>
        <TableContainer>
          <StyledTable {...getTableProps()}>
            <StyledThead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <StyledTh {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </StyledTh>
                  ))}
                </tr>
              ))}
            </StyledThead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <StyledTr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <StyledTd {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </StyledTd>
                    ))}
                  </StyledTr>
                );
              })}
            </tbody>
          </StyledTable>
        </TableContainer>
      </GraphTableContainer>
      <CardsContainer>
        <CardContainer>
          <span>
            Total Valid OTPs for this month till today: {initialTotalValidOtps}
          </span>
          <span style={{ display: "block", marginTop: "10px" }}>
            Total Payment for this month till today: $
            {parseFloat(initialTotalValidOtps * 0.01).toFixed(2)}
          </span>
        </CardContainer>
        <CardContainer>
          <span>
            Total Forecasted Valid OTPs for this month:{" "}
            {parseFloat(initialAverageOtps + initialTotalValidOtps).toFixed(0)}
          </span>
          <span style={{ display: "block", marginTop: "10px" }}>
            Total Forecasted Payment for this month: $
            {parseFloat(
              (initialAverageOtps + initialTotalValidOtps) * 0.01
            ).toFixed(2)}
          </span>
        </CardContainer>
      </CardsContainer>
    </div>
  );
};

export default MyComponent;
