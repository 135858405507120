import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Input,
  Space,
  Table,
  Layout,
  message,
  Alert,
  Typography,
  Grid,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Leaderboard from "./leaderboardSidebar";
import useLogout from "../useLogout";

const { Content, Sider } = Layout;
const { useBreakpoint } = Grid;
const apiUrl = process.env.REACT_APP_API_SMSIN;
const dataApiUrl = process.env.REACT_APP_OTPCountPerApp;
const appsWithTimestampUrl = process.env.REACT_APP_AppsWithTimestampUrl;
const toggleUrl = "https://i9qlv36tdb.execute-api.us-east-1.amazonaws.com/dev/";

const Testers = () => {
  const { Title } = Typography;
  const [messageApi, contextHolder] = message.useMessage();
  const [testers, setTesters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [filteredApps, setFilteredApps] = useState([]);
  const [appTimestamps, setAppTimestamps] = useState({});
  const [resultsExist, setResultsExist] = useState(false); // State to track if results exist
  const [extraOTPs, setExtraOTPs] = useState(false);
  const screens = useBreakpoint();
  const searchInput = useRef(null);
  const logout = useLogout();

  useEffect(() => {
    fetchData();
    fetchChartData();
    fetchAppTimestamps();
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const user = JSON.parse(storedUser);
    const roleId = user.roleId;

    if (roleId === 4) {
      applyFilter(); // Apply the filter whenever the testers data changes for role id 4
      filterOTPs();
    }
  }, [testers]);

  useEffect(() => {
    if (resultsExist) {
      const storedUser = localStorage.getItem("user");
      const user = JSON.parse(storedUser);
      const toggleResult = async () => {
        try {
          const response = await fetch(toggleUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ user_id: user.userId }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          console.log("Request was successful");
        } catch (error) {
          console.error("There was a problem with the fetch operation:", error);
        }
      };

      toggleResult();
    }
  }, [resultsExist]);

  useEffect(() => {
    if (extraOTPs) {
      const storedUser = localStorage.getItem("user");
      const user = JSON.parse(storedUser);
      const toggleResult = async () => {
        console.log(user.userId);
        try {
          const response = await fetch(toggleUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ user_id: user.userId }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          console.log(response);

          console.log("Request was successful");
        } catch (error) {
          console.error("There was a problem with the fetch operation:", error);
        }
      };

      toggleResult();
    }
  }, [extraOTPs]);

  const applyFilter = () => {
    const today = new Date().toISOString().split("T")[0];
    const todayData = testers.filter(
      (item) =>
        item.timestamp.startsWith(today) &&
        (item.country === "Ghana" || item.country === "Mali") // Check for Ghana or Mali
    );

    todayData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    const results = [];
    for (let i = 0; i < todayData.length - 9; i++) {
      const sender_id_1 = todayData[i].sender_id;
      const sender_id_2 = todayData[i + 1].sender_id;
      const sender_id_3 = todayData[i + 2].sender_id;
      const sender_id_4 = todayData[i + 3].sender_id;
      const sender_id_5 = todayData[i + 4].sender_id;
      const sender_id_6 = todayData[i + 5].sender_id;
      const sender_id_7 = todayData[i + 6].sender_id;
      const sender_id_8 = todayData[i + 7].sender_id;
      const sender_id_9 = todayData[i + 8].sender_id;
      const sender_id_10 = todayData[i + 9].sender_id;

      const country_1 = todayData[i].country;
      const country_2 = todayData[i + 1].country;
      const country_3 = todayData[i + 2].country;
      const country_4 = todayData[i + 3].country;
      const country_5 = todayData[i + 4].country;
      const country_6 = todayData[i + 5].country;
      const country_7 = todayData[i + 6].country;
      const country_8 = todayData[i + 7].country;
      const country_9 = todayData[i + 8].country;
      const country_10 = todayData[i + 9].country;

      const destination_numbers = [
        todayData[i].destination_number,
        todayData[i + 1].destination_number,
        todayData[i + 2].destination_number,
        todayData[i + 3].destination_number,
        todayData[i + 4].destination_number,
        todayData[i + 5].destination_number,
        todayData[i + 6].destination_number,
        todayData[i + 7].destination_number,
        todayData[i + 8].destination_number,
        todayData[i + 9].destination_number,
      ];

      const areAllUnique = (arr) => {
        const uniqueSet = new Set(arr);
        return uniqueSet.size === arr.length;
      };

      if (
        sender_id_1 === sender_id_2 &&
        sender_id_2 === sender_id_3 &&
        sender_id_3 === sender_id_4 &&
        sender_id_4 === sender_id_5 &&
        sender_id_5 === sender_id_6 &&
        sender_id_6 === sender_id_7 &&
        sender_id_7 === sender_id_8 &&
        sender_id_8 === sender_id_9 &&
        sender_id_9 === sender_id_10 &&
        country_1 === country_2 &&
        country_2 === country_3 &&
        country_3 === country_4 &&
        country_4 === country_5 &&
        country_5 === country_6 &&
        country_6 === country_7 &&
        country_7 === country_8 &&
        country_8 === country_9 &&
        country_9 === country_10 &&
        areAllUnique(destination_numbers)
      ) {
        results.push([todayData[i], todayData[i + 1], todayData[i + 2]]);
      }
    }

    setResultsExist(results.length > 0);
  };

  const filterOTPs = () => {
    const today = new Date().toISOString().split("T")[0];
    const todayData = testers.filter(
      (item) =>
        item.timestamp.startsWith(today) &&
        (item.country === "Ghana" || item.country === "Mali") // Check for Ghana or Mali
    );

    const senderCountryCount = {};
    const results = [];

    todayData.forEach((item) => {
      const sender_id = item.sender_id;
      const country = item.country;
      const destination_number = item.destination_number;

      if (!senderCountryCount[sender_id]) {
        senderCountryCount[sender_id] = {};
      }
      if (!senderCountryCount[sender_id][country]) {
        senderCountryCount[sender_id][country] = new Set();
      }

      senderCountryCount[sender_id][country].add(destination_number);
    });

    for (const sender_id in senderCountryCount) {
      for (const country in senderCountryCount[sender_id]) {
        if (senderCountryCount[sender_id][country].size > 70) {
          results.push(1);
        }
      }
    }

    setExtraOTPs(results.length > 0);
  };

  const fetchData = async () => {
    const storedUser = localStorage.getItem("user");
    const user = JSON.parse(storedUser);
    try {
      const response = await fetch(`${apiUrl}/${user.userId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch smsin");
      }
      const data = await response.json();
      console.log("Data:", data);
      console.log(data.body.inactive);
      if (data.body.inactive) {
        logout(); // Use the custom hook here
      } else {
        setTesters(data.body);
        setLoading(false);
      }
      //setTesters(data.body);
      //setLoading(false);
    } catch (error) {
      console.error("Error fetching smsin:", error);
    }
  };

  const fetchChartData = async () => {
    try {
      const response = await fetch(dataApiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch chart data");
      }
      const data = await response.json();

      console.log("Fetched data:", data);

      // Filter out the "WhatsApp" app from the data
      const filteredData = Object.entries(data).reduce(
        (acc, [country, values]) => {
          if (
            values &&
            typeof values === "object" &&
            Object.keys(values).length > 0
          ) {
            const filteredValues = Object.entries(values)
              .filter(([app]) => app !== "WhatsApp")
              .reduce((obj, [app, value]) => {
                obj[app] = value;
                return obj;
              }, {});
            acc[country] = filteredValues;
          }
          return acc;
        },
        {}
      );

      console.log("Filtered data (without WhatsApp):", filteredData);

      const formattedData = Object.keys(filteredData).map((country) => {
        return {
          country,
          ...filteredData[country],
        };
      });
      console.log("formattedData:", formattedData);
      setChartData(formattedData);
      filterApps(filteredData);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  const filterApps = (data) => {
    if (!data || typeof data !== "object") {
      console.error("Invalid data provided to filterApps");
      return;
    }

    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    const filtered = Object.entries(data).map(([country, apps]) => {
      if (!apps || typeof apps !== "object") {
        console.error(`Invalid apps data for country: ${country}`);
        return { country: capitalize(country), apps: [] };
      }

      const filteredApps = Object.entries(apps)
        .filter(([app, count]) => count > 70)
        .map(([app, count]) => ({ app, count }));

      return { country: capitalize(country), apps: filteredApps };
    });

    setFilteredApps(filtered);
  };

  const fetchAppTimestamps = async () => {
    try {
      const response = await fetch(appsWithTimestampUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch app timestamps");
      }
      const data = await response.json();
      setAppTimestamps(data);
    } catch (error) {
      console.error("Error fetching app timestamps:", error);
    }
  };

  const filteredTesters = testers.filter((message) => {
    if (message.sender_id === "WhatsApp") return false; // Filter out WhatsApp records
    const countryApps = appTimestamps[message.country];
    if (!countryApps) return true;
    const appTimestamp = countryApps[message.sender_id];
    return (
      !appTimestamp || new Date(message.timestamp) <= new Date(appTimestamp)
    );
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            type="primary"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      width: "20%", // Increase width for better visibility on small screens
      ...getColumnSearchProps("timestamp"),
      sorter: (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "ascend",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: "10%",
      ...getColumnSearchProps("country"),
      sorter: (a, b) => a.country.localeCompare(b.country),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Operator Name",
      dataIndex: "operator_name",
      key: "operator_name",
      width: "20%", // Increase width for better visibility on small screens
      ...getColumnSearchProps("operator_name"),
      sorter: (a, b) => a.operator_name.localeCompare(b.operator_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "B Number",
      dataIndex: "destination_number",
      key: "destination_number",
      width: "20%", // Increase width for better visibility on small screens
      ...getColumnSearchProps("destination_number"),
      sorter: (a, b) => a.destination_number - b.destination_number,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "A Number",
      dataIndex: "sender_id",
      key: "sender_id",
      width: "10%",
      ...getColumnSearchProps("sender_id"),
      sorter: (a, b) => a.sender_id - b.sender_id,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      width: "20%", // Increase width for better visibility on small screens
      ...getColumnSearchProps("content"),
      sorter: (a, b) => a.content - b.content,
      sortDirections: ["descend", "ascend"],
    },
  ];

  return (
    <Layout>
      {contextHolder}
      <Content
        style={{
          padding: screens.md ? "0 18px" : "0 5px", // Adjust padding for smaller screens
          minHeight: "100vh",
          background: "#fff",
        }}
      >
        <Alert
          message="Do not generate more than 50 OTPs for one App for one country"
          type="error"
          banner
          style={{
            marginBottom: "1%",
            padding: screens.md ? "10px" : "5px", // Adjust padding for smaller screens
            fontSize: screens.md ? "16px" : "12px", // Adjust font size for smaller screens
          }}
        />

        {chartData.length > 0 && (
          <ResponsiveContainer
            width="100%"
            height={screens.md ? 400 : 250} // Adjust height for smaller screens
            debounce={1} // To improve performance and responsiveness
          >
            <BarChart
              data={chartData}
              margin={{
                top: 20,
                right: screens.md ? 30 : 5, // Adjust margins for smaller screens
                left: screens.md ? 20 : -20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="country" />
              <YAxis />
              <Tooltip />
              {screens.md ? (
                <>
                  <Legend />
                </>
              ) : null}
              {Object.keys(chartData[0])
                .slice(1)
                .map((key) => (
                  <Bar
                    key={key}
                    dataKey={key}
                    fill={`#${Math.floor(Math.random() * 16777215).toString(
                      16
                    )}`}
                  />
                ))}
            </BarChart>
          </ResponsiveContainer>
        )}
        {filteredTesters && (
          <>
            <Alert
              message="Alert: If you have multiple tests on the same number for the same application then you won't be paid for it."
              type="error"
              banner
              style={{
                margin: screens.md ? "10px 0" : "5px 0", // Adjust margin for smaller screens
                padding: screens.md ? "10px" : "5px", // Adjust padding for smaller screens
                fontSize: screens.md ? "16px" : "12px", // Adjust font size for smaller screens
              }}
            />
            <Title level={screens.md ? 4 : 5}>Messages details</Title>
            <Table
              columns={screens.md ? columns : columns.slice(0, 3)} // Reduce columns on smaller screens
              dataSource={filteredTesters} // Use filtered data for table
              loading={loading}
              locale={{
                emptyText: loading ? "Loading..." : "No Data",
              }}
              pagination={{
                showSizeChanger: true,
                defaultPageSize: screens.md ? 10 : 5, // Adjust page size for smaller screens
                pageSizeOptions: ["5", "10", "20"],
              }}
              scroll={{ x: screens.md ? "100%" : "max-content" }} // Enable horizontal scroll for smaller screens
              style={{
                fontSize: screens.md ? "14px" : "12px", // Adjust font size for smaller screens
              }}
            />
          </>
        )}
        {/* <div>
          3 or more consecutive OTPs from the same brand for unique numbers:{" "}
          {resultsExist ? "true" : "false"}
        </div>
        <div>
          More than 70 OTPs from the same brand for unique numbers:{" "}
          {extraOTPs ? "true" : "false"}
        </div> */}
      </Content>
      {screens.md && (
        <Sider width={250} style={{ background: "#fff" }}>
          <Leaderboard />
        </Sider>
      )}
    </Layout>
  );
};

export default Testers;
