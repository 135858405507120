import React, { useState, useEffect } from "react";
import axios from "axios";
import { Input, Select, Radio, Button } from "antd";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

const AddTeamLead = ({ setOpen }) => {
  const teamsURL = process.env.REACT_APP_API_Team;
  const teamleadAPI = process.env.REACT_APP_API_Teamleads;

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [contact_number, setContactNumber] = useState("");
  const [gender, setGender] = useState("");
  const [team, setTeam] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [teams, setTeams] = useState([]);
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    contact_number: "",
    gender: "",
    team: "",
    confirmPassword: "",
  });

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    setLoading(true);
    try {
      const response = await axios.get(teamsURL);
      setTeams(response.data.body);
    } catch (error) {
      console.error("Error fetching teams:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    if (errors.first_name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        first_name: "",
      }));
    }
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    if (errors.last_name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        last_name: "",
      }));
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (errors.email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (errors.password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }
  };

  const handleContactNumberChange = (e) => {
    setContactNumber(e.target.value);
    if (errors.contact_number) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contact_number: "",
      }));
    }
  };

  const handleGenderChange = (value) => {
    setGender(value);
    if (errors.gender) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        gender: "",
      }));
    }
  };

  const handleTeamChange = (value) => {
    setTeam(value);
    setErrors((prevErrors) => ({ ...prevErrors, team: "" }));
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!password.trim()) {
      newErrors.password = "Please enter Password";
    } else if (password.trim().length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
    }

    const contactNumberRegex = /^(?:\+?92|0)\d{10}$/; // Regex for Pakistani phone numbers
    if (!contact_number.trim()) {
      newErrors.contact_number = "Please enter Contact Number";
    } else if (!contactNumberRegex.test(contact_number.trim())) {
      newErrors.contact_number =
        "Please enter a valid contact number (e.g., +92331514193 or 03313423987)";
    }

    if (!first_name.trim()) {
      newErrors.first_name = "Please enter First Name";
    }
    if (!last_name.trim()) {
      newErrors.last_name = "Please enter Last Name";
    }
    if (!email.trim()) {
      newErrors.email = "Please enter Email";
    }
    if (!gender) {
      newErrors.gender = "Please select Gender";
    }
    if (!team) {
      newErrors.team = "Please select Team";
    }
    if (password.trim() !== confirmPassword.trim()) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const selectedTeam = teams.find((t) => t.name === team);
      if (!selectedTeam) {
        throw new Error("Selected team not found.");
      }

      const team_id = selectedTeam.id;
      const response = await axios.post(teamleadAPI, {
        first_name,
        last_name,
        email,
        password,
        role_id: 3,
        gender,
        contact_number,
        team_id,
      });
      setOpen(false);
    } catch (error) {
      console.error("Error adding team lead:", error);
      setErrors({ general: "Failed to add team lead" });
    }
  };

  return (
    <>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Add Team Lead
          </Typography>
        </Toolbar>
      </AppBar>
      <List style={{ height: "100vh" }}>
        <Grid
          container
          style={{ height: "100%", justifyContent: "center" }}
          alignItems="center"
        >
          <Grid item xs={12} sm={8} md={6} lg={8}>
            <form onSubmit={handleSubmit} className="mx-10">
              <div className=" mb-4">
                <h2 className="text-2xl font-bold">Add Team Lead</h2>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <Input
                  value={first_name}
                  onChange={handleFirstNameChange}
                  placeholder="Enter First Name"
                  addonBefore="First Name"
                  className="mb-4"
                />
                {errors.first_name && (
                  <p className="text-[red] text-xs mt-1">{errors.first_name}</p>
                )}
                <Input
                  value={last_name}
                  onChange={handleLastNameChange}
                  placeholder="Enter Last Name"
                  addonBefore="Last Name"
                  className="mb-4"
                />
                {errors.last_name && (
                  <p className="text-[red] text-xs mt-1">{errors.last_name}</p>
                )}
                <Input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter Email"
                  addonBefore="Email"
                  className="mb-4"
                />
                {errors.email && (
                  <p className="text-[red] text-xs mt-1">{errors.email}</p>
                )}
                <Input
                  type="tel"
                  value={contact_number}
                  onChange={handleContactNumberChange}
                  placeholder="Enter Contact Number"
                  addonBefore="Contact Number"
                  className="mb-4"
                />
                {errors.contact_number && (
                  <p className="text-[red] text-xs mt-1">
                    {errors.contact_number}
                  </p>
                )}
                <Input.Password
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Enter Password"
                  addonBefore="Password"
                  className="mb-4"
                  iconRender={(visible) =>
                    visible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />
                  }
                />
                {errors.password && (
                  <p className="text-[red] text-xs mt-1">{errors.password}</p>
                )}
                <Input.Password
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  placeholder="Confirm Password"
                  addonBefore="Confirm Password"
                  className="mb-4"
                  iconRender={(visible) =>
                    visible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />
                  }
                />
                {errors.confirmPassword && (
                  <p className="text-[red] text-xs mt-1">
                    {errors.confirmPassword}
                  </p>
                )}
                <div>
                  <Radio.Group
                    onChange={(e) => handleGenderChange(e.target.value)}
                    value={gender}
                  >
                    <Radio value="male">Male</Radio>
                    <Radio value="female">Female</Radio>
                  </Radio.Group>
                  {errors.gender && (
                    <p className="text-[red] text-xs mt-1">{errors.gender}</p>
                  )}
                </div>
                <Select
                  value={team}
                  onChange={handleTeamChange}
                  placeholder="Select Team"
                  className="mb-4"
                >
                  {loading ? (
                    <Select.Option disabled>Loading...</Select.Option>
                  ) : (
                    teams.map((team) => (
                      <Select.Option key={team.id} value={team.name}>
                        {team.name}
                      </Select.Option>
                    ))
                  )}
                </Select>
                {errors.team && (
                  <p className="text-[red] text-xs mt-1">{errors.team}</p>
                )}
              </div>
              {errors.general && (
                <p className="text-red-500 text-xs mt-1">{errors.general}</p>
              )}
              <div className="flex justify-end">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn btn-xs sm:btn-sm md:btn-md lg:btn-md bg-primaryColor text-[white] ml-2"
                >
                  Add Team Lead
                </Button>
              </div>
            </form>
          </Grid>
        </Grid>
      </List>
    </>
  );
};

export default AddTeamLead;
