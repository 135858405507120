import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spin, Descriptions } from "antd";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

const ViewTeamDetails = ({ teamId, setOpen }) => {
  const [teamData, setTeamData] = useState(null);
  const [country, setCountry] = useState("");
  const teamsURL = process.env.REACT_APP_API_Team;
  const countryAPI = process.env.REACT_APP_API_Countries;

  useEffect(() => {
    const fetchTeamDetails = async () => {
      try {
        const response = await axios.get(`${teamsURL}/${teamId}`);
        setTeamData(response.data.body);
      } catch (error) {
        console.error("Error fetching team details:", error);
      }
    };

    fetchTeamDetails();
    return () => {
      setTeamData(null);
    };
  }, [teamId, teamsURL]);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get(
          `${countryAPI}/${teamData.country_id}`
        );
        setCountry(response.data.body.name);
      } catch (error) {
        console.error("Error fetching country:", error);
      }
    };

    if (teamData) {
      fetchCountry();
    }
  }, [teamData, countryAPI]);

  if (!teamData || !country) {
    return <Spin />;
  }

  return (
    <>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Team Details
          </Typography>
        </Toolbar>
      </AppBar>
      <List style={{ height: "50vh" }}>
        <Grid
          container
          style={{ height: "50%", justifyContent: "center" }}
          alignItems="center"
        >
          <Grid item xs={12} sm={8} md={6} lg={8}>
            <h2 className="text-2xl font-bold mt-8 mb-8">Team Details</h2>
            <Descriptions column={1} bordered>
              <Descriptions.Item label="Team ID">
                {teamData.id}
              </Descriptions.Item>
              <Descriptions.Item label="Team Name">
                {teamData.name}
              </Descriptions.Item>
              <Descriptions.Item label="Country">{country}</Descriptions.Item>
            </Descriptions>
          </Grid>
        </Grid>
      </List>
    </>
  );
};

export default ViewTeamDetails;
